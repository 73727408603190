import { createRef, FC, useEffect, useRef } from 'react';
import { useDrag } from '@use-gesture/react';
import classNames from 'classnames';
import { motion, useAnimation } from 'framer-motion';

import { SoundName } from '@/audio/AudioManager';
import { useSettings } from '@/context';
import emitter from '@/events/emitter';

import { getCleanWord } from '../utils/language';

import GridLetterTile, { GridLetterTileHandle } from './GridLetterTile';

interface PhraseWordGridHandlerProps {
    word: string;
    isCorrectAnswer: boolean;
    categoryColor: string;
    onCorrectWordComplete: () => void;
    vertical?: boolean;
    tileSize: number;
}

const PhraseWordGridHandler: FC<PhraseWordGridHandlerProps> = ({
    word,
    isCorrectAnswer,
    categoryColor,
    onCorrectWordComplete,
    vertical = false,
    tileSize
}) => {
    const containerRef = useRef<HTMLDivElement>(null);
    const { settings } = useSettings();
    const initialIndexRef = useRef<number | undefined>(undefined);
    const currentIndexRef = useRef<number | undefined>(undefined);
    const selectedIndicesRef = useRef<Set<number>>(new Set());

    const cleanWord = getCleanWord(word, settings.studyLanguage);
    const tileRefs = useRef<Array<React.RefObject<GridLetterTileHandle>>>([]);

    // Reinitialize tileRefs whenever cleanWord changes
    useEffect(() => {
        tileRefs.current = cleanWord
            .split('')
            .map(() => createRef<GridLetterTileHandle>());
    }, [cleanWord]);

    const controls = useAnimation();

    useEffect(() => {
        if (tileSize > 0) {
            // Animate opacity when tile size is set
            controls.start({ opacity: 1, transition: { duration: 0.5 } });
        }
    }, [tileSize, controls]);

    const calculateIndex = (x: number, y: number) => {
        if (vertical) {
            const totalTilesHeight = tileSize * cleanWord.length;
            if (y >= 0 && y <= totalTilesHeight) {
                const index = Math.floor(y / tileSize);
                if (index >= 0 && index < cleanWord.length) {
                    updateSelectedIndices(index);
                }
            }
        } else {
            const totalTilesWidth = tileSize * cleanWord.length;
            const containerWidth = containerRef.current?.clientWidth || 0;
            const startOffset = (containerWidth - totalTilesWidth) / 2;
            const relativeX = x - startOffset;
            if (relativeX >= 0 && relativeX <= totalTilesWidth) {
                const index = Math.floor(relativeX / tileSize);
                if (index >= 0 && index < cleanWord.length) {
                    updateSelectedIndices(index);
                }
            }
        }
    };

    const animateUpdatedSelection = (
        prevIndices: Set<number>,
        newIndices: Set<number>
    ) => {
        if (newIndices.size > prevIndices.size) {
            newIndices.forEach(index => {
                if (!prevIndices.has(index)) {
                    const tileRef = tileRefs.current[index];
                    if (tileRef && tileRef.current) {
                        tileRef.current.select();
                    }
                }
            });
        } else {
            prevIndices.forEach(index => {
                if (!newIndices.has(index)) {
                    const tileRef = tileRefs.current[index];
                    if (tileRef && tileRef.current) {
                        tileRef.current.deselect();
                    }
                }
            });
        }
    };

    const updateSelectedIndices = (index: number) => {
        if (index < 0 || index >= cleanWord.length) return;

        let initialIndex = initialIndexRef.current;
        if (initialIndex === undefined) {
            initialIndex = index;
            initialIndexRef.current = index;
        }

        const currentIndex = index;
        currentIndexRef.current = index;

        const newIndices = new Set<number>();

        if (initialIndex <= currentIndex) {
            for (let i = initialIndex; i <= currentIndex; i++) {
                newIndices.add(i);
            }
        } else {
            for (let i = initialIndex; i >= currentIndex; i--) {
                newIndices.add(i);
            }
        }

        animateUpdatedSelection(selectedIndicesRef.current, newIndices);
        selectedIndicesRef.current = newIndices;
    };

    const onGestureEnd = () => {
        const indicesArray = Array.from(selectedIndicesRef.current);
        let isInAscendingOrder = true;

        for (let i = 0; i < indicesArray.length - 1; i++) {
            if (indicesArray[i] > indicesArray[i + 1]) {
                isInAscendingOrder = false;
                break;
            }
        }

        if (
            selectedIndicesRef.current.size === cleanWord.length &&
            isInAscendingOrder &&
            isCorrectAnswer
        ) {
            tileRefs.current.forEach(ref => {
                ref.current?.remove();
            });
            onCorrectWordComplete();
        } else {
            emitter.emit('playSound', { sound: SoundName.Wrong });
            selectedIndicesRef.current.forEach(index => {
                const tileRef = tileRefs.current[index];
                if (tileRef && tileRef.current) {
                    tileRef.current.shake();
                }
            });
            animateUpdatedSelection(selectedIndicesRef.current, new Set());
            selectedIndicesRef.current.clear();
        }

        initialIndexRef.current = undefined;
        currentIndexRef.current = undefined;
        selectedIndicesRef.current.clear();
    };

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const bind: any = useDrag(({ xy: [x, y], last }) => {
        if (!containerRef.current) return;

        // Get container's bounding rect
        const containerRect = containerRef.current.getBoundingClientRect();

        // Calculate the coordinates relative to the container
        const relativeX = x - containerRect.left;
        const relativeY = y - containerRect.top;

        // Use these relative coordinates to calculate the tile index
        calculateIndex(relativeX, relativeY);

        if (last) onGestureEnd();
    });

    if (!tileSize) return null;

    return (
        <motion.div
            {...bind()}
            initial={{ opacity: 0 }}
            transition={{ duration: 0.5 }}
            className={classNames(`relative flex h-full w-full touch-none`, {
                'flex-col': vertical,
                'flex-row': !vertical
            })}
            ref={containerRef}
            animate={controls} // Use controls for all animations
        >
            {tileSize > 0 && (
                <div
                    className={`flex ${
                        vertical
                            ? 'flex-col items-start'
                            : 'flex-row items-start'
                    }`}
                >
                    {Array.from(cleanWord).map((letter, index) => (
                        <GridLetterTile
                            ref={tileRefs.current[index]}
                            key={index}
                            letter={letter}
                            size={tileSize}
                            selectedBackgroundColor={categoryColor}
                        />
                    ))}
                </div>
            )}
        </motion.div>
    );
};

export default PhraseWordGridHandler;

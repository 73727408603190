import { TextButton } from '@/components';
import { useLabels, useNavigation } from '@/context';

const TurnOnSoundScreen = () => {
    const { getLabel } = useLabels();
    const { navigate } = useNavigation();

    const onContinuePress = () => {
        navigate('WordsIntro');
    };

    return (
        <div className="flex h-full flex-col justify-center p-4">
            <div className="mb-6 w-full rounded-2xl bg-black bg-opacity-20 px-5 py-4">
                <h2 className="mb-8">
                    {getLabel('sound.turn-on-sound-title')}
                </h2>
                <p className="text-lg text-white">
                    {getLabel('sound.turn-on-sound-description')}
                </p>
            </div>
            <TextButton
                onClick={onContinuePress}
                text={getLabel('button.continue')}
            />
        </div>
    );
};

export default TurnOnSoundScreen;

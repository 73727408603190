import { type FC, useEffect, useState } from 'react';
import ReactGA from 'react-ga4';
import Tippy from '@tippyjs/react';
import classNames from 'classnames';
import { motion } from 'framer-motion';

import { SoundName } from '@/audio/AudioManager';
import { Dialogue, Panel, TextButton, TooltipContent } from '@/components';
import {
    useGameProgress,
    useLabels,
    useNavigation,
    useNewPhrasesGameSession,
    useNewWordsGameSession,
    useReviewGameSession,
    useSettings
} from '@/context';
import emitter from '@/events/emitter';
import useDisableClosingConfirmation from '@/hooks/useDisableClosingConfirmation';
import { useVibration } from '@/hooks/useVibration';
import FriendsModal from '@/screens-modal/FriendsModal';
import ShopModal from '@/screens-modal/ShopModal';
import StreakModal from '@/screens-modal/StreakModal';
import { commonStyles } from '@/styles/commonStyles';
import { CategoryInfoWithProgress } from '@/types/category';

import friends from '../../../assets/images/icons/friends.png';
import shop from '../../../assets/images/icons/shop.png';
import stamps from '../../../assets/images/icons/stamps.png';
import streak from '../../../assets/images/icons/streak.png';
import tasks from '../../../assets/images/icons/tasks.png';

import { CategoryCard } from './components/CategoryCard';

import 'tippy.js/animations/scale-subtle.css';

const MENU_ICONS = {
    shop: shop,
    friends: friends,
    streak: streak,
    tasks: tasks,
    stamps: stamps
};

const calculateCircleSize = (wordsToReview: number) => {
    const baseSize = 30;
    const increment = 10; // Increment size for each additional character
    const length = wordsToReview.toString().length;
    return baseSize + increment * (length - 1);
};

const ChooseCategoryScreen: FC = () => {
    useDisableClosingConfirmation();

    const { navigate } = useNavigation();
    const [isShopVisible, setIsShopVisible] = useState(false);
    const [isFriendsVisible, setIsFriendsVisible] = useState(false);
    const [isStreakVisible, setIsStreakVisible] = useState(false);
    const [isReviewDialogueVisible, setIsReviewDialogueVisible] =
        useState(false);
    const [levelText, setLevelText] = useState('');
    const [hintText, setHintText] = useState('');
    const {
        loadCategoriesToChooseFrom,
        chooseCategory,
        categoriesToChooseFrom,
        gameProgress,
        getWordsToReviewCount,
        getPhrasesToReviewCount,
        updateIsNoWordsToReviewWithSpacedRep,
        updateIsNoPhrasesToReviewWithSpacedRep
    } = useGameProgress();
    const {
        settings,
        setIsReviewWithoutSpacedRepViewed,
        updateIsReviewModeTooltipNeeded
    } = useSettings();
    const { resetGameSession } = useNewWordsGameSession();
    const { resetGameSession: resetPhrasesGameSession } =
        useNewPhrasesGameSession();
    const { resetReviewGameSession } = useReviewGameSession();
    const { getLabel } = useLabels();
    const { vibrateLight } = useVibration();

    const [itemsToReviewCount, setItemsToReviewCount] = useState(
        () => getWordsToReviewCount() + getPhrasesToReviewCount()
    );

    const [circleSize, setCircleSize] = useState(
        calculateCircleSize(itemsToReviewCount)
    );

    const [isReviewTooltipVisible, setIsReviewTooltipVisible] = useState(
        gameProgress.gameLevel === 3 && settings.isReviewModeTooltipNeeded
    );

    useEffect(() => {
        const handleAppStateChange = (nextAppState: string) => {
            if (nextAppState === 'active') {
                updateItemsToReviewCount();
            }
        };

        window.addEventListener('focus', () => handleAppStateChange('active'));

        return () => {
            window.removeEventListener('focus', () =>
                handleAppStateChange('active')
            );
        };
    }, []);

    const updateItemsToReviewCount = () => {
        const count = getWordsToReviewCount() + getPhrasesToReviewCount();
        setItemsToReviewCount(count);
        setCircleSize(calculateCircleSize(count));
    };

    useEffect(() => {
        if (itemsToReviewCount === 0) return;

        // Animation logic handled by Framer Motion in JSX
    }, [itemsToReviewCount]);

    useEffect(() => {
        resetGameSession();
        resetPhrasesGameSession();
        resetReviewGameSession();

        if (!categoriesToChooseFrom) {
            loadCategoriesToChooseFrom(settings.languageLevel);
        }
    }, []);

    useEffect(() => {
        if (!categoriesToChooseFrom) return;

        const impressiveLabel = getLabel('choose-category.impressive');
        const noTopicsLabel = getLabel('choose-category.no-topics');
        const oneTopicLabel = getLabel('choose-category.one-topic');
        const pickFirstTopicLabel = getLabel(
            'choose-category.pick-first-topic'
        );
        const pickNextTopicLabel = getLabel('choose-category.pick-next-topic');

        if (categoriesToChooseFrom.length === 0) {
            setLevelText(impressiveLabel);
            setHintText(noTopicsLabel);
        } else if (categoriesToChooseFrom.length === 1) {
            setHintText(oneTopicLabel);
        } else {
            setHintText(
                gameProgress.gameLevel === 1
                    ? pickFirstTopicLabel
                    : pickNextTopicLabel
            );
        }
    }, [categoriesToChooseFrom]);

    const onPickTopicPress = () => {
        navigate('ChooseAnotherCategory');
    };

    const onReviewPress = () => {
        vibrateLight();
        emitter.emit('playSound', { sound: SoundName.Click2 });
        if (itemsToReviewCount === 0) {
            updateIsNoWordsToReviewWithSpacedRep(true);
            updateIsNoPhrasesToReviewWithSpacedRep(true);
            if (settings.isReviewWithoutSpacedRepViewed) {
                navigate('ReviewPuzzle');
            } else {
                setIsReviewDialogueVisible(true);
                return;
            }
        } else {
            updateIsNoWordsToReviewWithSpacedRep(false);
            updateIsNoPhrasesToReviewWithSpacedRep(false);
            navigate('ReviewPuzzle');
        }
    };

    const onContinueReviewPress = () => {
        setIsReviewWithoutSpacedRepViewed();
        setIsReviewDialogueVisible(false);
        navigate('ReviewPuzzle');
    };

    const onReviewDialogueClose = () => {
        setIsReviewDialogueVisible(false);
    };

    const onChooseCategory = (category: CategoryInfoWithProgress) => {
        chooseCategory(category);
        if (settings.isSkipWordsIntro) {
            if (
                category.categoryProgressItem.wordProgressItems.length ===
                category.categoryInfo.words.length
            ) {
                navigate('PhrasePuzzle');
            } else {
                navigate('Puzzle');
            }
        } else {
            if (
                category.categoryProgressItem.wordProgressItems.length ===
                category.categoryInfo.words.length
            ) {
                navigate('PhrasesIntro');
            } else {
                gameProgress.gameLevel === 1
                    ? navigate('TurnOnSound')
                    : navigate('WordsIntro');
            }
        }
    };

    const onShopPress = () => {
        emitter.emit('playSound', { sound: SoundName.Click2 });
        vibrateLight();
        ReactGA.send({ hitType: 'pageview', page: 'Shop' });
        ReactGA.event({ category: 'Shop', action: 'Open shop' });
        setIsShopVisible(true);
    };

    const onStreakPress = () => {
        emitter.emit('playSound', { sound: SoundName.Click2 });
        vibrateLight();
        ReactGA.send({ hitType: 'pageview', page: 'Streak Modal' });
        ReactGA.event({ category: 'Streak', action: 'Open streak' });
        setIsStreakVisible(true);
    };

    const onFriendsPress = () => {
        emitter.emit('playSound', { sound: SoundName.Click2 });
        vibrateLight();
        ReactGA.send({ hitType: 'pageview', page: 'Friends Modal' });
        ReactGA.event({ category: 'Friends', action: 'Open friends' });
        setIsFriendsVisible(true);
    };

    if (!categoriesToChooseFrom) return null;

    return (
        <>
            {isReviewTooltipVisible && (
                <div className="fixed inset-0 z-20 bg-black opacity-70"></div>
            )}
            <div className="relative flex h-full flex-col justify-center pb-14 pt-2">
                <Panel className={commonStyles.panelWrapper}>
                    {levelText && <h1>{levelText}</h1>}
                    <p className="mb-5 font-nunito text-2xl font-bold text-white">
                        {hintText} {settings.uiLanguage !== 'ru' && '✨'}
                    </p>
                    {categoriesToChooseFrom.length > 0 && (
                        <div className="mb-5 flex flex-wrap items-start justify-around">
                            {categoriesToChooseFrom.map(category => (
                                <CategoryCard
                                    key={category.categoryInfo.id}
                                    category={category}
                                    onChooseCategory={onChooseCategory}
                                />
                            ))}
                        </div>
                    )}
                    {categoriesToChooseFrom.length > 1 && (
                        <TextButton
                            className="w-full py-2"
                            bgColor="bg-grey600"
                            textStyle="text-white"
                            textSizeStyle="text-base"
                            text={getLabel('choose-category.see-other-topics')}
                            onClick={onPickTopicPress}
                        />
                    )}
                </Panel>
                {gameProgress.gameLevel > 2 && (
                    <Tippy
                        content={
                            <TooltipContent
                                title={getLabel(
                                    'choose-category.review-learned-words'
                                )}
                                text={getLabel(
                                    'choose-category.review-tooltip-text'
                                )}
                                className={`w-[calc(100vw-16px)]`}
                            />
                        }
                        visible={
                            isReviewTooltipVisible &&
                            settings.isReviewModeTooltipNeeded
                        }
                        placement="top-start"
                        onClickOutside={() => {
                            setTimeout(() => {
                                setIsReviewTooltipVisible(false);
                                void updateIsReviewModeTooltipNeeded(false);
                            }, 100);
                        }}
                        duration={300}
                        animation="scale-subtle"
                    >
                        <div
                            className={classNames(
                                'relative flex w-full',
                                isReviewTooltipVisible && 'z-30'
                            )}
                        >
                            <motion.button
                                whileTap={{ scale: 0.95 }}
                                initial={{ scale: 1 }}
                                animate={{ scale: 1 }}
                                className="flex w-full items-center justify-center rounded-2xl bg-grey700 bg-opacity-80 p-3 px-4 shadow-extraDark"
                                onClick={onReviewPress}
                            >
                                <span className="font-nunito text-base font-extraBold text-white">
                                    {getLabel('choose-category.review', true)}
                                </span>
                                {itemsToReviewCount > 0 && (
                                    <motion.div
                                        className="absolute right-0 top-[-10px] flex items-center justify-center rounded-full bg-orange"
                                        style={{
                                            width: `${circleSize}px`,
                                            height: `${circleSize}px`
                                        }}
                                        initial={{ scale: 1 }}
                                        animate={{
                                            opacity: 1,
                                            scale: [1, 1.2, 1],
                                            transition: {
                                                duration: 2.5,
                                                repeat: Infinity,
                                                repeatType: 'loop'
                                            }
                                        }}
                                    >
                                        <span className="font-bold text-white">
                                            {itemsToReviewCount}
                                        </span>
                                    </motion.div>
                                )}
                            </motion.button>
                        </div>
                    </Tippy>
                )}
                <BottomMenu
                    onFriendsPress={onFriendsPress}
                    onShopPress={onShopPress}
                    onStreakPress={onStreakPress}
                />
                <Dialogue
                    text={getLabel('choose-category.review-without-bonus')}
                    onButton1Press={onContinueReviewPress}
                    onButton2Press={onReviewDialogueClose}
                    onClose={onReviewDialogueClose}
                    isVisible={isReviewDialogueVisible}
                    button1Text={getLabel('button.yes')}
                    button2Text={getLabel('button.no')}
                />
                <ShopModal
                    isVisible={isShopVisible}
                    onClose={() => setIsShopVisible(false)}
                />
                <FriendsModal
                    isVisible={isFriendsVisible}
                    onClose={() => setIsFriendsVisible(false)}
                />
                <StreakModal
                    isVisible={isStreakVisible}
                    onClose={() => setIsStreakVisible(false)}
                />
            </div>
        </>
    );
};

export default ChooseCategoryScreen;

const BottomMenu = ({
    onFriendsPress,
    onShopPress,
    onStreakPress
}: {
    onFriendsPress: () => void;
    onShopPress: () => void;
    onStreakPress: () => void;
}) => {
    const { getLabel } = useLabels();
    const { friends, gameProgress } = useGameProgress();
    const { settings } = useSettings();
    const { currentProfileScreen } = useNavigation();

    const [showFriendsIndicator, setShowFriendsIndicator] = useState(false);

    useEffect(() => {
        const unclaimedReward = friends?.find(friend => friend.canBeClaimed);
        const friendsIntroNeeded =
            !settings.isFriendsModalViewed && gameProgress.gameLevel === 4;
        setShowFriendsIndicator(!!unclaimedReward || friendsIntroNeeded);
    }, [friends, settings.isFriendsModalViewed, gameProgress?.gameLevel]);

    if (currentProfileScreen) return null;

    const buttons = [
        {
            label: getLabel('menu.friends'),
            value: 'friends',
            handler: onFriendsPress,
            showIndicator: showFriendsIndicator
        },
        { label: getLabel('shop'), value: 'shop', handler: onShopPress },
        {
            label: getLabel('streak'),
            value: 'streak',
            handler: onStreakPress
        }
    ];

    return (
        <div className="fixed bottom-0 left-0 flex h-20 w-full justify-center rounded-t-3xl border-t border-white/40 bg-grey/90 pb-4">
            {buttons.map(button => (
                <MenuButton
                    button={button}
                    key={button.value}
                    onClick={button.handler}
                    showIndicator={button.showIndicator}
                />
            ))}
        </div>
    );
};

const MenuButton = ({
    button,
    onClick,
    showIndicator
}: {
    button: { label: string; value: string };
    onClick: () => void;
    showIndicator?: boolean;
}) => {
    return (
        <div
            className={classNames(
                'relative',
                'flex w-1/5 flex-col items-center justify-center'
            )}
            onClick={onClick}
        >
            <img
                src={MENU_ICONS[button.value]}
                className={classNames('mb-1.5 h-5')}
            />
            <p
                className={classNames(
                    'font-nunito text-xs tracking-wider text-white'
                )}
            >
                {button.label}
            </p>
            {showIndicator && (
                <motion.div
                    className="absolute right-3 top-2 h-3 w-3 rounded-full bg-orange"
                    initial={{ scale: 1 }}
                    animate={{
                        scale: [0.8, 1.2, 0.8],
                        transition: {
                            duration: 2.5,
                            repeat: Infinity,
                            repeatType: 'loop'
                        }
                    }}
                ></motion.div>
            )}
        </div>
    );
};

import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { useAsyncFn } from 'react-use';
import { motion } from 'framer-motion';

import { SoundName } from '@/audio/AudioManager';
import { CrossFadeImage, TextButton } from '@/components';
import { useGameProgress, useSettings } from '@/context';
import { useLabels } from '@/context/LabelsContext';
import { useNavigation } from '@/context/NavigationContext';
import emitter from '@/events/emitter';

const OpenLandscapeScreen = () => {
    const { navigate } = useNavigation();
    const { settings } = useSettings();
    const { getLabel } = useLabels();
    const {
        streak,
        nextLandscape,
        prepareNextLandscape,
        resetNextLandscape,
        unlockLandscape
    } = useGameProgress();

    const [isVisible, setIsVisible] = useState(true); // Controls visibility after animation
    const [shouldAnimate, setShouldAnimate] = useState(false); // Controls animation
    const [isNavigating, setIsNavigating] = useState(false);

    useEffect(() => {
        if (!nextLandscape) {
            prepareNextLandscape();
        }
    }, [nextLandscape, prepareNextLandscape]);

    const [handleUnlockState, doHandleUnlock] = useAsyncFn(async () => {
        if (!nextLandscape) {
            toast.error(getLabel('error.no-landscape-to-unlock'));
            return;
        }

        try {
            await unlockLandscape(settings.studyLanguage, nextLandscape);
            setIsNavigating(true);
            emitter.emit('playSound', { sound: SoundName.Reveal });
            // Trigger the fade-out animation
            setShouldAnimate(true);

            setTimeout(() => {
                resetNextLandscape();
                setIsVisible(false);
                navigate(streak.rewardClaimed ? 'ChooseCategory' : 'Streak');
            }, 800); // Match the duration of the fade-out animation
        } catch (error) {
            console.error('Error unlocking landscape:', error);
            toast.error(getLabel('error.failed-to-unlock-landscape'));
            // Re-throw to allow useAsyncFn to capture the error
            throw error;
        }
    }, [
        unlockLandscape,
        settings.studyLanguage,
        nextLandscape,
        getLabel,
        streak
    ]);

    const onContinuePress = () => {
        doHandleUnlock();
    };

    if (!nextLandscape || !isVisible) return null;

    const isLoading = handleUnlockState.loading || isNavigating;

    return (
        <motion.div
            className="flex h-full flex-col justify-center"
            initial={{ opacity: 1 }}
            animate={shouldAnimate ? { opacity: 0 } : { opacity: 1 }}
            transition={{ duration: 0.8 }}
        >
            <div className="mb-6 flex w-full flex-col items-center rounded-2xl bg-grey700 bg-opacity-85 px-5 py-4">
                <h3>{nextLandscape.name}</h3>
                <CrossFadeImage
                    src={nextLandscape.imageUrl}
                    alt={nextLandscape.name}
                    containerClass="my-6 h-64 w-64"
                    className="rounded-full"
                />
            </div>
            <TextButton
                onClick={onContinuePress}
                text={getLabel('new-landscape.discover')}
                isDisabled={isLoading}
                isLoading={isLoading}
            />
        </motion.div>
    );
};

export default OpenLandscapeScreen;

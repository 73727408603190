import { type FC } from 'react';
import { openLink, retrieveLaunchParams } from '@telegram-apps/sdk-react';

import { ErrorBoundary } from '@/ErrorBoundary.tsx';
import { init } from '@/init.ts';

import splash from '../assets/images/splash.png';

import Panel from './components/Panel.tsx';
import { App } from './App.tsx';

const GENERIC_ERROR_MESSAGE = {
    en: 'Yikes! Something went wrong. But we are already working on a fix and will be back soon.',
    ru: 'Упс! Что-то пошло не так. Но мы уже работаем над тем, чтобы все исправить, и совсем скоро вернемся.'
};

const OLD_TELEGRAM_VERSION_ERROR_MESSAGE = {
    en: 'Oops! Seems like you are using an outdated version of Telegram. Please update it to the newest version to continue playing Lingoscapes.',
    ru: 'Ой! Похоже, у вас установлена устаревшая версия Telegram. Пожалуйста, обновите приложение, чтобы продолжить играть в Lingoscapes.'
};

const UPDATE_TELEGRAM_BUTTON_LABEL = {
    en: 'Update Telegram',
    ru: 'Обновить Telegram'
};

const ErrorBoundaryError: FC<{ error: unknown }> = ({ error }) => {
    let userLanguage = 'en';
    try {
        const { initData } = retrieveLaunchParams();
        userLanguage = initData.user?.languageCode ?? 'en';
    } catch (e) {
        console.error('Error retrieving launch params:', e);
    }

    const errorString =
        error instanceof Error
            ? error.message
            : typeof error === 'string'
              ? error
              : JSON.stringify(error);

    // Check for the specific error message and redirect
    if (
        errorString.includes(
            'Unable to retrieve launch parameters from any known source. Perhaps, you have opened your app outside Telegram?'
        )
    ) {
        window.location.href = 'https://lingoscapes.com/';
        return null; // Prevent further rendering
    }

    const isOutdatedVersionError = errorString.includes(
        'unsupported in Mini Apps version'
    );

    let errorMessage: string;

    if (isOutdatedVersionError) {
        errorMessage =
            OLD_TELEGRAM_VERSION_ERROR_MESSAGE[userLanguage] ??
            OLD_TELEGRAM_VERSION_ERROR_MESSAGE.en;
    } else {
        errorMessage =
            GENERIC_ERROR_MESSAGE[userLanguage] ?? GENERIC_ERROR_MESSAGE.en;
    }

    console.error('Showing ErrorBoundaryError:', error);

    return (
        <div
            className="fixed inset-0 bg-cover bg-center bg-no-repeat p-4"
            style={{ backgroundImage: `url(${splash})` }}
        >
            <div className="absolute inset-0 h-full w-full bg-black/50"></div>
            <div className="flex h-full items-center justify-center">
                <Panel className="z-10">
                    <div className="mb-2 text-3xl">😕</div>
                    <div className="text-xl leading-8 text-white">
                        {errorMessage}
                    </div>
                    {isOutdatedVersionError && (
                        <div className="mt-3 flex w-full justify-center py-2">
                            <UpdateTelegramButton
                                label={
                                    UPDATE_TELEGRAM_BUTTON_LABEL[
                                        userLanguage
                                    ] ?? UPDATE_TELEGRAM_BUTTON_LABEL.en
                                }
                            />
                        </div>
                    )}
                </Panel>
            </div>
        </div>
    );
};

const Inner: FC = () => {
    init(retrieveLaunchParams().startParam === 'debug' || import.meta.env.DEV);

    return <App />;
};

export const Root: FC = () => (
    <ErrorBoundary fallback={ErrorBoundaryError}>
        <Inner />
    </ErrorBoundary>
);

const UpdateTelegramButton = ({ label }: { label: string }) => {
    const handleUpdateTelegram = () => {
        const platform = getPlatform();

        let storeUrl = '';

        if (platform === 'android') {
            storeUrl =
                'https://play.google.com/store/apps/details?id=org.telegram.messenger';
        } else if (platform === 'ios') {
            storeUrl =
                'https://apps.apple.com/app/telegram-messenger/id686449807';
        } else {
            storeUrl = 'https://telegram.org/';
        }

        openLink(storeUrl, {
            tryInstantView: true
        });
    };

    return (
        <button
            className="rounded-3xl bg-blue px-4 py-3 text-xl font-semibold text-white"
            onClick={handleUpdateTelegram}
        >
            {label}
        </button>
    );
};

function getPlatform(): 'android' | 'ios' | 'unknown' {
    const userAgent = navigator.userAgent || '';
    const maxTouchPoints = navigator.maxTouchPoints || 0;

    // Check for iOS devices
    if (
        /iPad|iPhone|iPod/.test(userAgent) ||
        (userAgent.includes('Mac') && maxTouchPoints > 1)
    ) {
        return 'ios';
    }

    // Check for Android devices
    if (/android/i.test(userAgent)) {
        return 'android';
    }

    return 'unknown';
}

export default UpdateTelegramButton;

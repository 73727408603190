import { type FC, useState } from 'react';

import { Dialogue, HintButton, ModalInfo } from '@/components';
import { useLabels, useNavigation } from '@/context';

import exit from '../../../assets/images/icons/exit.png';
import question from '../../../assets/images/icons/question.png';

import 'tippy.js/animations/scale-subtle.css';

interface PhraseHintsPanelProps {
    onLeavePuzzle: () => void;
    isReview?: boolean;
}

const PhraseHintsPanel: FC<PhraseHintsPanelProps> = ({
    onLeavePuzzle,
    isReview = false
}) => {
    const { getLabel } = useLabels();
    const [isPauseDialogVisible, setIsPauseDialogVisible] = useState(false);
    const { navigate } = useNavigation();

    const [isShowInstructions, setIsShowInstructions] = useState(false);

    const onPausePress = () => setIsPauseDialogVisible(true);
    const onLeavePuzzlePress = () => onLeavePuzzle();
    const onSeeNewWordsAgainPress = () => navigate('PhrasesIntro');
    const onHelpPress = () => setIsShowInstructions(true);

    return (
        <>
            <div className="flex justify-end">
                <div className="flex gap-2">
                    {/* Question Button */}
                    <HintButton
                        onButtonPress={onHelpPress}
                        icon={<img src={question} className="h-5" alt="Help" />}
                    />
                    {/* Exit Button */}
                    <HintButton
                        onButtonPress={onPausePress}
                        icon={<img src={exit} className="h-5" alt="Exit" />}
                    />
                </div>
            </div>
            <ModalInfo
                isVisible={isShowInstructions}
                onClose={() => setIsShowInstructions(false)}
                description={getLabel('phrase-puzzle.choose-word')}
            />
            <Dialogue
                isVisible={isPauseDialogVisible}
                onClose={() => setIsPauseDialogVisible(false)}
                title={getLabel('puzzle.game-on-hold')}
                text={
                    isReview
                        ? getLabel('puzzle.are-you-sure')
                        : getLabel('puzzle.if-you-leave')
                }
                onButton1Press={onLeavePuzzlePress}
                onButton2Press={isReview ? undefined : onSeeNewWordsAgainPress}
                button1Text={getLabel('puzzle.leave-this-puzzle')}
                button2Text={
                    isReview
                        ? undefined
                        : getLabel('puzzle.see-new-words-again')
                }
            />
        </>
    );
};

export default PhraseHintsPanel;

import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { useAsyncFn } from 'react-use';
import { motion } from 'framer-motion';
import { CountUp } from 'use-count-up';

import { SoundName } from '@/audio/AudioManager';
import { Panel, TextButton } from '@/components';
import {
    useGameConfig,
    useGameProgress,
    useLabels,
    useNavigation,
    useReviewGameSession,
    useSettings
} from '@/context';
import emitter from '@/events/emitter';
import { resultScreenStyles } from '@/styles/resultScreenStyles';
import { getCleanWord } from '@/utils/language';

import coinIcon from '../../../assets/images/icons/coin.png';
import xpIcon from '../../../assets/images/icons/xp.png';

const ReviewResultsScreen = () => {
    const { getLabel } = useLabels();
    const { navigate } = useNavigation();
    const {
        updateStreak,
        addCoins,
        addXp,
        isNoWordsToReviewWithSpacedRep,
        isNoPhrasesToReviewWithSpacedRep
    } = useGameProgress();
    const { reviewGameSession, completeReviewGameSession } =
        useReviewGameSession();
    const { settings } = useSettings();
    const { gameConfig } = useGameConfig();

    const [coins, setCoins] = useState(0);
    const [bonusCoins, setBonusCoins] = useState(0);
    const [xp, setXp] = useState(0);
    const [animate, setAnimate] = useState(false);
    const [isNavigating, setIsNavigating] = useState(false);

    // **Handle Animations and Sounds Only Once on Mount**
    useEffect(() => {
        const earnedWordCoins = reviewGameSession.foundWords.reduce(
            (acc, word) =>
                acc + getCleanWord(word.word, settings.studyLanguage).length,
            0
        );

        const earnedPhraseCoins = reviewGameSession.foundPhrases.reduce(
            (acc, phrase) => acc + phrase.word.length,
            0
        );

        const bonusCoinsValue = reviewGameSession.correctWords.reduce(
            (acc, word) =>
                acc +
                getCleanWord(word.word, settings.studyLanguage).length * 2,
            0
        );

        const totalCoins = earnedWordCoins + earnedPhraseCoins;

        const earnedXp =
            isNoWordsToReviewWithSpacedRep && isNoPhrasesToReviewWithSpacedRep
                ? totalCoins *
                  gameConfig.xpPerCoinRewardForReviewWithoutSpacedRep
                : totalCoins * gameConfig.xpPerCoinReward;

        const timeoutCoins = setTimeout(() => {
            emitter.emit('playSound', { sound: SoundName.Coin });
            setCoins(totalCoins);
            setXp(earnedXp);
            setAnimate(true);
        }, 300);

        const timeoutBonusCoins = setTimeout(() => {
            setBonusCoins(bonusCoinsValue);
        }, 1300);

        return () => {
            clearTimeout(timeoutCoins);
            clearTimeout(timeoutBonusCoins);
        };
    }, []); // **Empty dependency array ensures this runs only once on mount**

    // **Reset Animation State After Animation Completes**
    useEffect(() => {
        if (animate) {
            // Reset animation after coins and XP animations
            const resetAnimation = setTimeout(() => {
                setAnimate(false);
            }, 600);

            return () => clearTimeout(resetAnimation);
        }
    }, [animate]);

    // **Handle Progress Update and Navigation**
    const handleProgressUpdateAndNavigate = async () => {
        try {
            const [streak] = await Promise.all([
                updateStreak(),
                completeReviewGameSession(
                    reviewGameSession.correctWords,
                    reviewGameSession.correctPhrases,
                    reviewGameSession.foundWords,
                    reviewGameSession.foundPhrases
                ),
                addCoins(coins + bonusCoins),
                addXp(settings.studyLanguage, xp)
            ]);
            setIsNavigating(true);
            navigate(streak.rewardClaimed ? 'ChooseCategory' : 'Streak');
        } catch (error) {
            console.error('Error during continue action:', error);
            toast.error(getLabel('error.failed-to-continue'));
            throw error; // **Re-throw to allow useAsyncFn to capture the error**
        }
    };

    // **Handle 'Continue' Button Click with useAsyncFn**
    const [handleContinueState, doHandleContinue] = useAsyncFn(async () => {
        await handleProgressUpdateAndNavigate();
    }, [
        addCoins,
        addXp,
        coins,
        bonusCoins,
        xp,
        settings.studyLanguage,
        navigate,
        getLabel
    ]);

    const onContinuePress = async () => {
        await doHandleContinue();
    };

    const isLoading = handleContinueState.loading || isNavigating;

    return (
        <div className="flex h-full flex-col justify-center">
            <Panel
                className={`${resultScreenStyles.panel} flex flex-col items-center`}
            >
                <h1>{getLabel('common.great-job')}</h1>
                <div className="my-4 flex w-full justify-around px-4">
                    <div className={`${resultScreenStyles.coinsWrapper}`}>
                        <motion.img
                            src={xpIcon}
                            className={`${resultScreenStyles.icon} h-16 w-16`}
                            animate={animate ? { scale: [1, 1.05, 1] } : {}}
                            transition={animate ? { duration: 0.6 } : {}}
                            alt="XP Icon"
                        />
                        <span
                            className={`${resultScreenStyles.counter} text-white200`}
                        >
                            +
                            <CountUp
                                key={xp}
                                isCounting
                                end={xp}
                                duration={1}
                            />
                        </span>
                    </div>
                    <div className={`${resultScreenStyles.coinsWrapper}`}>
                        <motion.img
                            src={coinIcon}
                            className={`${resultScreenStyles.icon} h-16 w-16`}
                            animate={animate ? { scale: [1, 1.05, 1] } : {}}
                            transition={animate ? { duration: 0.6 } : {}}
                            alt="Coin Icon"
                        />
                        <span
                            className={`${resultScreenStyles.counter} text-white200`}
                        >
                            +
                            <CountUp
                                key={coins}
                                isCounting
                                end={coins}
                                duration={1}
                            />
                        </span>
                    </div>
                </div>
                {reviewGameSession.correctWords.length > 0 && (
                    <div className="mt-4 flex flex-col items-center">
                        <h3>
                            {getLabel(
                                'review-results-screen.bonus-for-correct-answers'
                            )}
                        </h3>
                        <div className="mt-2 flex items-center justify-center">
                            <span className="text-lg font-bold text-white200">
                                <CountUp
                                    key={bonusCoins}
                                    isCounting
                                    end={bonusCoins}
                                    duration={0.5}
                                />
                            </span>
                            <img
                                src={coinIcon}
                                className="mb-1 ml-2 h-6 w-6"
                                alt="Bonus Coin Icon"
                            />
                        </div>
                    </div>
                )}
            </Panel>
            <TextButton
                onClick={onContinuePress}
                text={getLabel('button.continue')}
                isDisabled={isLoading}
                isLoading={isLoading}
            />
        </div>
    );
};

export default ReviewResultsScreen;

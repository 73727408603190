import { type FC, useEffect, useState } from 'react';
import { motion } from 'framer-motion';

import { ProgressBar } from '@/components';
import {
    useGameProgress,
    useNavigation,
    useNewPhrasesGameSession,
    useSettings
} from '@/context';
import useDisableVerticalSwipe from '@/hooks/useDisableVerticalSwipe';
import useEnableClosingConfirmation from '@/hooks/useEnableClosingConfirmation';
import { PhraseInfoLocalized } from '@/types/category';

import emitter, { CorrectPhraseAnswerGivenPayload } from '../../events/emitter';

import PhraseGameStep from './PhraseGameStep';
import PhraseHintsPanel from './PhraseHintsPanel';

const PhrasePuzzleScreen: FC = () => {
    useDisableVerticalSwipe();
    useEnableClosingConfirmation();

    const { navigate } = useNavigation();
    const { settings } = useSettings();
    const { gameSession, goToNextGameSessionStep, updateGameSessionCoins } =
        useNewPhrasesGameSession();
    const { gameProgress } = useGameProgress();
    const [currentPhrase, setCurrentPhrase] = useState<PhraseInfoLocalized>();

    const [currentStepIndex, setCurrentStepIndex] = useState(
        gameSession.currentStep
    );

    useEffect(() => {
        setCurrentPhrase(
            gameSession.phrasesToStudy[gameSession.currentStep - 1]
        );
    }, [gameSession.currentStep]);

    useEffect(() => {
        const handleCorrectAnswerGiven = ({
            phrase
        }: CorrectPhraseAnswerGivenPayload) => {
            updateGameSessionCoins(phrase.word.length);
        };

        emitter.on('correctPhraseAnswerGiven', handleCorrectAnswerGiven);

        return () => {
            emitter.off('correctPhraseAnswerGiven', handleCorrectAnswerGiven);
        };
    }, [updateGameSessionCoins]);

    useEffect(() => {
        if (gameSession.finished) {
            setCurrentStepIndex(gameSession.currentStep + 1);
            setTimeout(() => {
                console.log('PhrasePuzzleScreen navigate to Results');
                navigate('PhrasePuzzleResults');
            }, 750);
        } else {
            setCurrentStepIndex(gameSession.currentStep);
        }
    }, [
        gameSession.currentStep,
        gameSession.finished,
        settings?.studyLanguage,
        navigate
    ]);

    const onAllPhrasesFound = () => {
        goToNextGameSessionStep();
    };

    const onLeavePuzzle = () => {
        navigate('ChooseCategory');
    };

    if (!currentPhrase) {
        return null;
    }

    const totalSteps =
        gameProgress.gameLevel === 1 ? 3 : gameSession.totalSteps;

    console.log('gameSession.currentStep', gameSession.currentStep);
    console.log('currentStepIndex', currentStepIndex);
    console.log('finished', gameSession.finished);

    return (
        <div className="flex h-full flex-col justify-center">
            <div className="-mt-2.5 mb-1 h-2">
                <ProgressBar
                    progressBarValue={(currentStepIndex - 1) / totalSteps}
                    bgColor="orange"
                    borderColor="border-none"
                    unfilledColor="bg-grey700/30"
                    height="h-1.5"
                />
            </div>
            <PhraseGameStep
                key={`${gameSession.currentStep}-${currentPhrase.id}`}
                phrase={currentPhrase}
                onAnswerFound={onAllPhrasesFound}
            />
            <motion.div
                className="mb-2 mt-5 px-3"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1, transition: { duration: 0.5 } }}
            >
                <PhraseHintsPanel onLeavePuzzle={onLeavePuzzle} />
            </motion.div>
        </div>
    );
};

PhrasePuzzleScreen.displayName = 'PhrasePuzzleScreen';

const PhrasePuzzleScreenWrapper: FC = () => {
    const { currentCategory, phrasesToStudy } = useGameProgress();

    const { gameSession, initGameSession } = useNewPhrasesGameSession();

    useEffect(() => {
        if (!currentCategory) return;

        if (!gameSession.isReadyToPlay) {
            console.log('phrases to study', phrasesToStudy);
            initGameSession(currentCategory, phrasesToStudy);
        }
    }, [
        currentCategory,
        phrasesToStudy,
        initGameSession,
        gameSession.isReadyToPlay
    ]);

    if (!gameSession.isReadyToPlay) return null;

    return <PhrasePuzzleScreen />;
};

export default PhrasePuzzleScreenWrapper;

import { type FC, useEffect, useState } from 'react';

import TextButton from '@/components/buttons/TextButton';
import CloseIcon from '@/components/icons/CloseIcon';
import Modal from '@/components/Modal';
import { useGameProgress, useLabels } from '@/context';

import coinIcon from '../../../../assets/images/icons/coin.png';

interface UnlockCategoryModalProps {
    isVisible: boolean;
    onButton1Press: () => void;
    onButton2Press?: () => void;
    onClose: () => void;
    button1Loading?: boolean;
    unlockCategoryPrice: number;
}

const UnlockCategoryModal: FC<UnlockCategoryModalProps> = ({
    isVisible,
    onButton1Press,
    onButton2Press,
    onClose,
    button1Loading = false,
    unlockCategoryPrice
}) => {
    const { getLabel } = useLabels();
    const { gameProgress } = useGameProgress();

    const [currentCoins, setCurrentCoins] = useState(gameProgress.coins);

    useEffect(() => {
        setCurrentCoins(gameProgress.coins);
    }, [isVisible]);

    const handleClose = () => {
        onClose();
    };

    return (
        <Modal isVisible={isVisible} onClose={onClose} outline>
            <div className="mb-8 flex items-start justify-between">
                <h2 className="font-bold text-white">
                    {getLabel('category.open-new-topic')}
                </h2>
                <button
                    className="pt-2"
                    onClick={handleClose}
                    aria-label="Close"
                >
                    <CloseIcon height={16} width={16} color="white" />
                </button>
            </div>

            {/* Display Current Coins and Unlock Price */}
            <div className="mb-8 rounded-xl bg-black/30">
                <div className="flex flex-col">
                    <div className="mb-4 flex items-center justify-between rounded-xl bg-green bg-opacity-30 p-2">
                        <span className="mr-2 text-white">
                            {getLabel('common.current-balance')}:
                        </span>
                        <div className="flex items-center">
                            <span className="mr-1 font-semibold text-white">
                                {currentCoins}
                            </span>
                            <img
                                src={coinIcon}
                                alt="Coins"
                                className="h-5 w-5"
                            />
                        </div>
                    </div>

                    <div className="flex items-center justify-between rounded-xl bg-gray-800 bg-opacity-60 p-2">
                        <span className="mr-2 text-white">
                            {getLabel('common.price')}:
                        </span>
                        <div className="flex items-center">
                            <span className="mr-1 font-semibold text-white">
                                {unlockCategoryPrice}
                            </span>
                            <img
                                src={coinIcon}
                                alt="Coins"
                                className="h-5 w-5"
                            />
                        </div>
                    </div>
                </div>
            </div>

            {/* Action Buttons */}
            <div className="mb-3 flex flex-col gap-2.5">
                <TextButton
                    onClick={button1Loading ? undefined : onButton1Press}
                    text={getLabel('button.yes')}
                    verticalPadding="py-2"
                    isLoading={button1Loading}
                    isDisabled={button1Loading}
                />
                <TextButton
                    className="mt-2"
                    onClick={onButton2Press}
                    text={getLabel('button.no')}
                    verticalPadding="py-2"
                    isDisabled={button1Loading}
                />
            </div>
        </Modal>
    );
};

export default UnlockCategoryModal;

import {
    createContext,
    type FC,
    ReactNode,
    useCallback,
    useContext,
    useMemo,
    useState
} from 'react';

import { PhraseInfoLocalized, WordInfoLocalized } from '@/types/category';
import { WORDS_PER_REVIEW_STEP } from '@/utils/constants';

import { useGameProgress } from './GameProgressContext';
import { useSettings } from './SettingsContext';

interface ReviewGameSessionContextType {
    reviewGameSession: ReviewGameSession;
    completeReviewGameSession: (
        correctWords: WordInfoLocalized[],
        correctPhrases: PhraseInfoLocalized[],
        allWords: WordInfoLocalized[],
        allPhrases: PhraseInfoLocalized[]
    ) => Promise<void>;
    updateReviewGameSessionSteps: (
        currentStep: number,
        totalSteps: number
    ) => void;
    resetReviewGameSession: () => void;
    addFoundWord: (word: WordInfoLocalized) => void;
    addCorrectWord: (word: WordInfoLocalized) => void;
    addFoundPhrase: (phrase: PhraseInfoLocalized) => void;
    addCorrectPhrase: (phrase: PhraseInfoLocalized) => void;
    initializeReviewSteps: (
        words: WordInfoLocalized[],
        phrases: PhraseInfoLocalized[]
    ) => void;
}

interface ReviewGameSession {
    correctWords: WordInfoLocalized[];
    foundWords: WordInfoLocalized[];
    correctPhrases: PhraseInfoLocalized[];
    foundPhrases: PhraseInfoLocalized[];
    currentStep: number;
    totalSteps: number;
    steps: ReviewStep[];
}

type ReviewStep =
    | {
          type: 'words';
          words: WordInfoLocalized[];
      }
    | {
          type: 'phrase';
          phrase: PhraseInfoLocalized;
      };

const NEW_REVIEW_GAME_SESSION: ReviewGameSession = {
    correctWords: [],
    foundWords: [],
    correctPhrases: [],
    foundPhrases: [],
    currentStep: 1,
    totalSteps: 5,
    steps: []
};

export const ReviewGameSessionContext = createContext<
    ReviewGameSessionContextType | undefined
>(undefined);

interface ReviewGameSessionProviderProps {
    children: ReactNode;
}

export const ReviewGameSessionProvider: FC<ReviewGameSessionProviderProps> = ({
    children
}) => {
    const { settings } = useSettings();
    const { updateReviewProgress, resetCategories } = useGameProgress();

    const [reviewGameSession, setReviewSessionProgress] =
        useState<ReviewGameSession>(NEW_REVIEW_GAME_SESSION);

    const updateReviewGameSessionSteps = useCallback(
        (currentStep: number, totalSteps: number) => {
            setReviewSessionProgress(prev => ({
                ...prev,
                currentStep,
                totalSteps
            }));
        },
        []
    );

    const resetReviewGameSession = useCallback(() => {
        setReviewSessionProgress(NEW_REVIEW_GAME_SESSION);
    }, []);

    const addFoundWord = useCallback((word: WordInfoLocalized) => {
        setReviewSessionProgress(prev => ({
            ...prev,
            foundWords: [...prev.foundWords, word]
        }));
    }, []);

    const addCorrectWord = useCallback((word: WordInfoLocalized) => {
        setReviewSessionProgress(prev => ({
            ...prev,
            correctWords: [...prev.correctWords, word]
        }));
    }, []);

    const addFoundPhrase = useCallback((phrase: PhraseInfoLocalized) => {
        setReviewSessionProgress(prev => ({
            ...prev,
            foundPhrases: [...prev.foundPhrases, phrase]
        }));
    }, []);

    const addCorrectPhrase = useCallback((phrase: PhraseInfoLocalized) => {
        setReviewSessionProgress(prev => ({
            ...prev,
            correctPhrases: [...prev.correctPhrases, phrase]
        }));
    }, []);

    /**
     * Helper function to create a wordsStep with the correct type literal.
     */
    const createWordsStep = (words: WordInfoLocalized[]): ReviewStep => ({
        type: 'words',
        words
    });

    /**
     * Helper function to create a phrase step with the correct type literal.
     */
    const createPhraseStep = (phrase: PhraseInfoLocalized): ReviewStep => ({
        type: 'phrase',
        phrase
    });

    /**
     * Initializes the review steps using WORDS_PER_REVIEW_STEP for words steps,
     * includes phrases as individual steps, and shuffles the combined steps.
     */
    const initializeReviewSteps = useCallback(
        (words: WordInfoLocalized[], phrases: PhraseInfoLocalized[]) => {
            // Calculate the number of words steps based on WORDS_PER_REVIEW_STEP
            const numberOfWordsSteps = Math.ceil(
                words.length / WORDS_PER_REVIEW_STEP
            );
            const selectedWords = words.slice(
                0,
                numberOfWordsSteps * WORDS_PER_REVIEW_STEP
            );

            // Group words into chunks of WORDS_PER_REVIEW_STEP
            const wordsSteps: ReviewStep[] = [];
            for (
                let i = 0;
                i < selectedWords.length;
                i += WORDS_PER_REVIEW_STEP
            ) {
                const wordGroup = selectedWords.slice(
                    i,
                    i + WORDS_PER_REVIEW_STEP
                );
                wordsSteps.push(createWordsStep(wordGroup));
            }

            // Select phrases (keeping previous logic or adjust as needed)
            const selectedPhrases = phrases.slice(0, 3); // Adjust the number as per requirements

            const phraseSteps: ReviewStep[] =
                selectedPhrases.map(createPhraseStep);

            // Combine words steps and phrase steps
            const steps: ReviewStep[] = [...wordsSteps, ...phraseSteps];

            // Shuffle the steps using Fisher-Yates algorithm for better randomness
            for (let i = steps.length - 1; i > 0; i--) {
                const j = Math.floor(Math.random() * (i + 1));
                [steps[i], steps[j]] = [steps[j], steps[i]];
            }

            setReviewSessionProgress(prev => ({
                ...prev,
                steps,
                totalSteps: steps.length,
                currentStep: 1
            }));
        },
        []
    );

    /**
     * Completes the review game session by updating the state, sending progress to the API,
     * and resetting categories.
     */
    const completeReviewGameSession = useCallback(
        async (
            correctWords: WordInfoLocalized[],
            correctPhrases: PhraseInfoLocalized[],
            allWords: WordInfoLocalized[],
            allPhrases: PhraseInfoLocalized[]
        ) => {
            // Update the review session state
            setReviewSessionProgress(prev => ({
                ...prev,
                correctWords,
                foundWords: allWords,
                correctPhrases,
                foundPhrases: allPhrases
            }));

            // Update the review status in the backend
            await updateReviewProgress(
                settings.studyLanguage,
                correctWords,
                correctPhrases
            );
            resetCategories();
        },
        [settings, updateReviewProgress, resetCategories]
    );

    /**
     * Memoizes the context value to prevent unnecessary re-renders of consuming components.
     */
    const contextValue = useMemo(
        () => ({
            reviewGameSession,
            completeReviewGameSession,
            updateReviewGameSessionSteps,
            resetReviewGameSession,
            addFoundWord,
            addCorrectWord,
            addFoundPhrase,
            addCorrectPhrase,
            initializeReviewSteps
        }),
        [
            reviewGameSession,
            completeReviewGameSession,
            updateReviewGameSessionSteps,
            resetReviewGameSession,
            addFoundWord,
            addCorrectWord,
            addFoundPhrase,
            addCorrectPhrase,
            initializeReviewSteps
        ]
    );

    return (
        <ReviewGameSessionContext.Provider value={contextValue}>
            {children}
        </ReviewGameSessionContext.Provider>
    );
};

export const useReviewGameSession = () => {
    const context = useContext(ReviewGameSessionContext);
    if (!context) {
        throw new Error(
            'useReviewGameSession must be used within a ReviewGameSessionProvider'
        );
    }
    return context;
};

import { getPluralizer } from './pluralizeFactory';

export const dayPluralizer = (
    count: number,
    getLabel: (key: string) => string,
    uiLanguage: string
): string => {
    const pluralizer = getPluralizer(uiLanguage);

    const dayVariants = {
        one: getLabel('streak.day--one').toLocaleLowerCase(),
        few: getLabel('streak.days--few').toLocaleLowerCase(),
        many: getLabel('streak.days--many').toLocaleLowerCase(),
        other: getLabel('streak.days--many').toLocaleLowerCase()
    };

    return pluralizer.pluralize(count, dayVariants);
};

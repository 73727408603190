import {
    forwardRef,
    ReactNode,
    useImperativeHandle,
    useRef,
    useState
} from 'react';
import classNames from 'classnames';
import { motion, useAnimation } from 'framer-motion';

import { SoundName } from '@/audio/AudioManager';
import emitter from '@/events/emitter';
import { useVibration } from '@/hooks/useVibration';

import coinIcon from '../../../assets/images/icons/coin.png';

interface HintButtonProps {
    onButtonPress: () => void;
    icon: ReactNode;
    badge?: number;
    price?: number;
    className?: string;
}

export interface HintButtonHandle {
    animate: () => void;
    animateActionRequired: () => void;
    stopAnimation: () => void;
    animateSpendCoins: (price: number) => void;
    getBoundingClientRect: () => DOMRect | undefined;
}

interface SpendAnimation {
    id: number;
    price: number;
}

const HintButton = forwardRef<HintButtonHandle, HintButtonProps>(
    ({ onButtonPress, icon, price, badge, className }, ref) => {
        const controls = useAnimation();
        const { vibrateLight } = useVibration();
        const [spendAnimations, setSpendAnimations] = useState<
            SpendAnimation[]
        >([]);
        const [animationCounter, setAnimationCounter] = useState(0);

        const buttonRef = useRef<HTMLButtonElement>(null);

        const handlePress = () => {
            vibrateLight();
            emitter.emit('playSound', { sound: SoundName.Click2 });
            onButtonPress();
        };

        useImperativeHandle(ref, () => ({
            animate() {
                controls.start({
                    scale: [1, 1.2, 1],
                    transition: { duration: 0.3, ease: 'easeInOut' }
                });
            },
            animateActionRequired() {
                controls.start({
                    scale: [0.95, 1.25, 0.95],
                    backgroundColor: ['#FAAC38', '#FCCB38', '#FAAC38'],
                    transition: {
                        duration: 3,
                        ease: 'easeInOut',
                        repeat: Infinity
                    }
                });
            },
            stopAnimation() {
                controls.stop();
                controls.set({ scale: 1, backgroundColor: '#FAAC38' });
            },
            animateSpendCoins(price: number) {
                setAnimationCounter(prev => prev + 1);
                setSpendAnimations(prevAnimations => [
                    ...prevAnimations,
                    { id: animationCounter, price }
                ]);
            },
            getBoundingClientRect() {
                return (
                    buttonRef.current?.getBoundingClientRect() || new DOMRect()
                );
            }
        }));

        return (
            <motion.button
                onClick={handlePress}
                className={classNames(
                    'relative flex h-9 w-9 items-center justify-center rounded-xl bg-orange',
                    className
                )}
                whileTap={{ scale: 0.95 }}
                initial={{ scale: 1 }}
                animate={controls}
                ref={buttonRef}
            >
                <div className="flex items-center justify-center">{icon}</div>
                {price && (
                    <div className="absolute mt-12 flex h-5 items-center justify-around rounded-md bg-black/50 pr-1">
                        <img
                            src={coinIcon}
                            alt="Coin Icon"
                            className="h-4 w-4"
                        />
                        <span className="text-xs font-bold text-white">
                            {price}
                        </span>
                    </div>
                )}
                {badge !== undefined && (
                    <div className="bg-red-500 absolute left-1/2 top-5 flex h-3 w-3 -translate-x-1/2 -translate-y-1/2 transform items-center justify-center rounded-full">
                        <span className="text-xs font-bold text-orange">
                            {badge}
                        </span>
                    </div>
                )}
                {spendAnimations.map(anim => (
                    <motion.div
                        key={anim.id}
                        initial={{ opacity: 1, y: 0 }}
                        animate={{ opacity: 0, y: -30 }}
                        transition={{ duration: 1.25 }}
                        className="absolute"
                        style={{
                            top: '-20px',
                            left: '-5px',
                            transform: 'translateX(-50%)',
                            pointerEvents: 'none' // Prevents interaction
                        }}
                        onAnimationComplete={() => {
                            // Remove animation from the array once it completes
                            setSpendAnimations(prevAnimations =>
                                prevAnimations.filter(a => a.id !== anim.id)
                            );
                        }}
                    >
                        <div className="flex items-center">
                            <img
                                src={coinIcon}
                                alt="Coin Icon"
                                className="h-6 w-6"
                            />
                            <span className="font-bold text-white">
                                -{anim.price}
                            </span>
                        </div>
                    </motion.div>
                ))}
            </motion.button>
        );
    }
);

HintButton.displayName = 'HintButton';

export default HintButton;

import { useEffect, useState } from 'react';
import { Swiper, SwiperClass, SwiperSlide } from 'swiper/react';

import { ProgressBar } from '@/components';
import CloseIcon from '@/components/icons/CloseIcon';
import VolumeIcon from '@/components/icons/VolumeIcon';
import { useGameProgress, useLabels, useNavigation } from '@/context';
import emitter from '@/events/emitter';
import useDisableVerticalSwipe from '@/hooks/useDisableVerticalSwipe';
import { useVibration } from '@/hooks/useVibration';
import SingleWordGridHandler from '@/puzzle/SingleWordGridHandler';

import PhraseView from './components/PhraseView';

import 'swiper/css';

const VERTICAL_TILES_THRESHOLD = 7;

const PhrasesIntroScreen = () => {
    useDisableVerticalSwipe();

    const { vibrateLight, vibrateMedium } = useVibration();

    const { navigate } = useNavigation();
    const { getLabel } = useLabels();
    const { gameProgress, currentCategory, phrasesToStudy } = useGameProgress();

    const [foundPhraseIndex, setFoundPhraseIndex] = useState<number | null>(
        null
    );
    const [currentPhraseIndex, updateCurrentPhraseIndex] = useState(0);
    const [swiper, setSwiper] = useState<SwiperClass>();

    useEffect(() => {
        if (
            currentPhraseIndex < 0 ||
            currentPhraseIndex >= phrasesToStudy.length
        )
            return;

        if (phrasesToStudy[currentPhraseIndex].audioUrl) {
            emitter.emit('playAudioByUrl', {
                audioUrl: phrasesToStudy[currentPhraseIndex].audioUrl
            });
        }
    }, [currentPhraseIndex, phrasesToStudy]);

    const onClosePress = () => {
        vibrateLight();
        navigate('ChooseCategory');
    };

    const onAudioPress = () => {
        emitter.emit('playAudioByUrl', {
            audioUrl: phrasesToStudy[currentPhraseIndex].audioUrl
        });
    };

    const onGoToNextPhrase = () => {
        const nextIndex = currentPhraseIndex + 1;

        if (nextIndex === phrasesToStudy.length) {
            updateCurrentPhraseIndex(nextIndex);
            setTimeout(() => {
                navigate('PhrasePuzzle');
            }, 750);
        } else {
            setTimeout(() => {
                updateCurrentPhraseIndex(nextIndex);
                swiper?.slideTo(nextIndex, 500);
            }, 750);
        }
    };

    const onPhraseComplete = () => {
        vibrateMedium();
        setFoundPhraseIndex(currentPhraseIndex);
        onGoToNextPhrase();
    };

    return (
        <div className="flex h-full flex-col overflow-hidden">
            {/* Header */}
            <div className="flex items-center justify-between p-4">
                <p className="uppercase text-white">
                    {getLabel('phrases-intro.new-phrase')}
                </p>
                <button className="p-2" onClick={onClosePress}>
                    <CloseIcon width={16} height={16} color="white" />
                </button>
            </div>

            {/* Content */}
            <div className="flex h-full w-full">
                <Swiper
                    pagination={true}
                    navigation={true}
                    className="mySwiper"
                    onSwiper={swiperInstance => setSwiper(swiperInstance)}
                    allowTouchMove={false} // Disable manual sliding
                >
                    {phrasesToStudy.map((phrase, index) => (
                        <SwiperSlide
                            key={phrase.id}
                            className="flex h-full w-full flex-col items-center justify-center"
                        >
                            <PhraseView
                                phrase={phrase}
                                found={foundPhraseIndex === index}
                                onClick={onPhraseComplete}
                            />
                            <SingleWordGridHandler
                                word={phrase.word}
                                categoryColor={phrase.categoryColor}
                                onWordComplete={onPhraseComplete}
                                isVertical={
                                    phrase.sentence.length >
                                    VERTICAL_TILES_THRESHOLD
                                }
                                showCursor={
                                    gameProgress.gameLevel === 1 &&
                                    currentPhraseIndex === 0 &&
                                    index === 0
                                }
                            />
                        </SwiperSlide>
                    ))}
                </Swiper>
            </div>

            {/* Footer */}
            <div className="flex items-start justify-between gap-2 p-4">
                <div className="flex basis-1/2 flex-wrap justify-start">
                    <div className="pr-1.5 font-nunito text-sm uppercase tracking-wide text-white">
                        {getLabel('common.topic')}:{' '}
                    </div>
                    <div className="font-nunito text-sm font-bold uppercase tracking-wide text-white">
                        {currentCategory.categoryInfo.name}
                    </div>
                </div>
                <div className="flex flex-1 items-center space-x-2">
                    <p className="text-sm uppercase text-white">
                        {currentPhraseIndex}/{phrasesToStudy.length}
                    </p>
                    <ProgressBar
                        progressBarValue={
                            currentPhraseIndex / phrasesToStudy.length
                        }
                        bgColor={currentCategory.categoryInfo.color}
                        borderColor="border-none"
                        unfilledColor="bg-grey600"
                        height="h-2.5"
                    />
                    <button onClick={onAudioPress} className="pl-2 pr-1">
                        <VolumeIcon width={18} height={18} color="white" />
                    </button>
                </div>
            </div>
        </div>
    );
};

export default PhrasesIntroScreen;

import { FC, useEffect, useState } from 'react';
import ReactGA from 'react-ga4';
import { useReward } from 'react-rewards';
import { toast } from 'react-toastify';
import { openInvoice } from '@telegram-apps/sdk-react';
import { CountUp } from 'use-count-up';

import { generateInvoiceApi } from '@/api/client';
import { SoundName } from '@/audio/AudioManager';
import TextButton from '@/components/buttons/TextButton';
import CloseIcon from '@/components/icons/CloseIcon';
import Modal from '@/components/Modal';
import { useGameConfig, useGameProgress, useLabels } from '@/context';
import emitter from '@/events/emitter';
import { useVibration } from '@/hooks/useVibration';
import { commonStyles } from '@/styles/commonStyles';

import coinIcon from '../../assets/images/icons/coin.png';
import star from '../../assets/images/star.png';

interface ShopModalProps {
    isVisible: boolean;
    onClose: () => void;
    notEnoughCoinsMode?: boolean;
}

interface ShopItemProps {
    coins: number;
    starsAmount: number;
    code: string;
    reward: () => void;
    isMostPopular?: boolean;
}

const ShopItem: FC<ShopItemProps> = ({
    coins,
    starsAmount,
    code,
    reward,
    isMostPopular
}) => {
    const { getLabel } = useLabels();
    const { addCoins } = useGameProgress();
    const [isLoading, setIsLoading] = useState(false);

    const handlePurchase = async () => {
        ReactGA.event({
            category: 'Shop',
            action: 'Click purchase button',
            label: code
        });
        try {
            setIsLoading(true);

            const invoiceLink = await generateInvoiceApi(code);
            const status = await openInvoice(invoiceLink, 'url');
            if (status === 'paid') {
                ReactGA.event({
                    category: 'Shop',
                    action: 'Payment successful',
                    label: code
                });
                emitter.emit('playSound', { sound: SoundName.PurchaseSuccess });
                reward();
                await addCoins(coins);
            } else if (status === 'failed') {
                console.error('Payment failed:', status);
                ReactGA.event({
                    category: 'Shop',
                    action: 'Payment failed',
                    label: code
                });
                toast.error(getLabel('shop.payment-failed'));
            }
        } catch (error) {
            console.error('Processing invoice error:', error);
            toast.error(getLabel('shop.processing-invoice-error'));
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <div className="relative flex items-center justify-between rounded-2xl border border-[#E1A814] bg-[#E1A814]/10 px-3 pb-3.5 pt-3">
            {isMostPopular && (
                <div className="absolute -top-3 left-6 rounded-full bg-pink-600 px-3 py-1 text-xs font-bold text-white shadow-lg">
                    {getLabel('shop.most-popular', true)}
                </div>
            )}

            <div className="mt-0.5 flex items-center">
                <img src={coinIcon} alt="LingoCoins" className="mr-2 h-8 w-8" />
                <span className="text-xl font-extrabold text-[#FFC328]">
                    +{coins}
                </span>
            </div>
            <TextButton
                className="min-w-24"
                verticalPadding="py-2"
                horizontalPadding="px-4"
                bgColor="bg-[#E1A814]"
                textStyle="text-white200"
                onClick={handlePurchase}
                isLoading={isLoading}
            >
                <div className="flex items-center justify-center">
                    {starsAmount}
                    <img src={star} className="ml-2 h-6 pb-0.5" alt="Star" />
                </div>
            </TextButton>
        </div>
    );
};

const ShopModal: FC<ShopModalProps> = ({
    isVisible,
    onClose,
    notEnoughCoinsMode
}) => {
    const { getLabel } = useLabels();
    const { vibrateLight } = useVibration();
    const { gameConfig } = useGameConfig();
    const { gameProgress } = useGameProgress();

    const { reward } = useReward('rewardId', 'confetti', {
        angle: 110,
        lifetime: 600,
        elementSize: 6,
        elementCount: 350,
        startVelocity: 45,
        colors: ['#FAAC38', '#4FC0E8'],
        spread: 40,
        decay: 0.96
    });

    const shopItems = gameConfig?.shopItems || [];

    const [previousCoins, setPreviousCoins] = useState(gameProgress.coins);
    const [coins, setCoins] = useState(gameProgress.coins);

    useEffect(() => {
        if (!isVisible) {
            setPreviousCoins(gameProgress.coins);
            setCoins(gameProgress.coins);
        } else {
            setCoins(gameProgress.coins);
        }
    }, [isVisible, gameProgress.coins]);

    const onCoinsCounterComplete = () => {
        setPreviousCoins(gameProgress.coins);
        setCoins(gameProgress.coins);
    };

    const onCloseClick = () => {
        emitter.emit('playSound', { sound: SoundName.Click2 });
        vibrateLight();
        onClose();
    };

    return (
        <>
            <div className="absolute -right-24 -top-4 z-20" id="rewardId"></div>

            <Modal isVisible={isVisible} onClose={onClose} outline>
                <div className={commonStyles.modalPanel}>
                    <div className="flex items-start justify-between">
                        <div>
                            {notEnoughCoinsMode ? (
                                <h2 className="mb-2 text-lg text-white">
                                    {getLabel(
                                        'shop.you-dont-have-enough-coins'
                                    )}
                                </h2>
                            ) : (
                                <>
                                    <h1>{getLabel('shop')}</h1>
                                    <p className="text-lg text-white">
                                        {getLabel('shop.turn-stars-into-coins')}
                                    </p>
                                </>
                            )}
                        </div>
                        <button
                            onClick={onCloseClick}
                            aria-label="Close"
                            className="mt-2 pb-3 pl-3"
                        >
                            <CloseIcon height={16} width={16} color="white" />
                        </button>
                    </div>

                    {/* Current Coins Counter */}
                    <div className="my-4 flex items-center rounded-xl bg-gray-800 bg-opacity-60 p-2">
                        <span className="mr-2 text-white">
                            {getLabel('common.current-balance')}:
                        </span>
                        <span className="mr-1 font-semibold text-white">
                            <CountUp
                                key={coins}
                                isCounting
                                start={previousCoins}
                                end={coins}
                                duration={1.5}
                                onComplete={onCoinsCounterComplete}
                            />
                        </span>
                        <img src={coinIcon} alt="Coins" className="h-6 w-6" />
                    </div>

                    <div className="flex w-full flex-col gap-6">
                        {shopItems.map((item, index) => (
                            <ShopItem
                                key={item.code}
                                coins={item.coins}
                                starsAmount={item.priceInStars.amount}
                                code={item.code}
                                reward={reward}
                                isMostPopular={index === 1}
                            />
                        ))}
                    </div>
                </div>
            </Modal>
        </>
    );
};

export default ShopModal;

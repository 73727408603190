import { type FC, useEffect, useRef, useState } from 'react';
import { motion, useAnimation } from 'framer-motion';

import { SoundName } from '@/audio/AudioManager';
import ModalInfo from '@/components/ModalInfo';
import { useContent, useGameProgress, useLabels } from '@/context';
import emitter from '@/events/emitter';
import { useVibration } from '@/hooks/useVibration';
import { WordInfoLocalized } from '@/types/category';
import { shuffle } from '@/utils/array';
import { toUpperCaseWord } from '@/utils/language';

import AnswerOption from '../questions/AnswerOption';
import QuestionInstructions from '../questions/QuestionInstructions';
import { BACKGROUND_COLOR_FOUND } from '../questions/questionStyles';

import ChooseTranslationModal from './ChooseTranslationModal';
import { questionPanelStyles } from './questionsPanelStyles';

interface ChooseTranslationQuestionsPanelProps {
    words: WordInfoLocalized[];
    selectionColor: string;
    studyLanguage: string;
    wordsFound: number;
}

const ChooseTranslationQuestionsPanel: FC<
    ChooseTranslationQuestionsPanelProps
> = ({ words, selectionColor, studyLanguage, wordsFound }) => {
    const [mounted, setMounted] = useState(true);
    const { getWordsByCategory } = useContent();
    const { getLabel } = useLabels();
    const { vibrateLight } = useVibration();
    const { gameProgress } = useGameProgress();

    const [showInstructions, setShowInstructions] = useState(true);
    const [isShowInstructionsModal, setIsShowInstructionsModal] =
        useState(false);
    const [selectedWord, setSelectedWord] = useState('');
    const [chooseTranslationForWord, setChooseTranslationForWord] = useState<
        WordInfoLocalized | undefined
    >(undefined);
    const [isOptionsModalVisible, setIsOptionsModalVisible] = useState(false);

    // Keep translation options stable even after chooseTranslationForWord is cleared
    const [modalTranslationOptions, setModalTranslationOptions] = useState<
        JSX.Element[] | null
    >(null);

    const renderedOptionsForWordRef = useRef<WordInfoLocalized | undefined>(
        undefined
    );
    const chooseTranslationForWordRef = useRef<WordInfoLocalized | undefined>(
        undefined
    );
    chooseTranslationForWordRef.current = chooseTranslationForWord;

    const scaleControls = useAnimation();

    useEffect(() => {
        setMounted(true);
        return () => {
            setMounted(false);
        };
    }, []);

    useEffect(() => {
        if (!chooseTranslationForWord || !mounted) return;

        emitter.emit('playSound', { sound: SoundName.Card });
        scaleControls.start({ scale: [1, 1.12, 1] });

        return () => {
            scaleControls.stop();
        };
    }, [chooseTranslationForWord, scaleControls, mounted]);

    const resetState = (word?: WordInfoLocalized) => {
        if (word && word.id !== chooseTranslationForWordRef.current?.id) return;

        setSelectedWord('');
        setChooseTranslationForWord(undefined);
        renderedOptionsForWordRef.current = undefined;
        setModalTranslationOptions(null);
    };

    useEffect(() => {
        const handleWordSelectionChanged = ({ word }) => {
            setShowInstructions(false);
            setSelectedWord(word);

            if (word !== '') {
                // Ensure we reset if a new word is selected
                setChooseTranslationForWord(prev => {
                    if (prev) return undefined;
                    return prev;
                });
            }
        };

        const handleValidWordSelected = ({ wordId }) => {
            vibrateLight();
            const word = words.find(w => w.id === wordId);
            setChooseTranslationForWord(word);
            setIsOptionsModalVisible(true);
        };

        const handleInvalidWordSelected = () => {
            resetState();
        };

        const handleCorrectAnswerGiven = ({ word }) => {
            if (word.id !== chooseTranslationForWord?.id) return;
            setIsOptionsModalVisible(false);
            // Delay resetState until after the modal finishes closing
            setTimeout(() => {
                resetState(word);
            }, 300);
        };

        const handleAudioHintPlayWord = ({ wordId }) => {
            const word = words.find(w => w.id === wordId);
            if (!word) return;
            emitter.emit('playAudioByUrl', { audioUrl: word.audioUrl });
        };

        emitter.on('validWordSelected', handleValidWordSelected);
        emitter.on('invalidWordSelected', handleInvalidWordSelected);
        emitter.on('wordSelectionChanged', handleWordSelectionChanged);
        emitter.on('correctAnswerGiven', handleCorrectAnswerGiven);
        emitter.on('audioHintPlayWord', handleAudioHintPlayWord);

        return () => {
            emitter.off('validWordSelected', handleValidWordSelected);
            emitter.off('invalidWordSelected', handleInvalidWordSelected);
            emitter.off('wordSelectionChanged', handleWordSelectionChanged);
            emitter.off('correctAnswerGiven', handleCorrectAnswerGiven);
            emitter.off('audioHintPlayWord', handleAudioHintPlayWord);
        };
    }, [chooseTranslationForWord]);

    // Compute translation options and store them in state so they remain stable
    useEffect(() => {
        if (!chooseTranslationForWord) return;
        if (
            chooseTranslationForWord.id ===
            renderedOptionsForWordRef.current?.id
        ) {
            return;
        }

        renderedOptionsForWordRef.current = chooseTranslationForWord;

        const wordsInCategory = getWordsByCategory(
            chooseTranslationForWord.categoryId
        );

        // Filter out words with the same translation
        const filteredWords = wordsInCategory.filter(
            w =>
                w.id !== chooseTranslationForWord.id &&
                w.translation !== chooseTranslationForWord.translation
        );

        shuffle(filteredWords);

        // Find words with the same part of speech but different translation
        const wordsWithTheSamePartOfSpeech = filteredWords.filter(
            w => w.partOfSpeech === chooseTranslationForWord.partOfSpeech
        );

        // Select another option
        const anotherOption =
            wordsWithTheSamePartOfSpeech.length > 0
                ? wordsWithTheSamePartOfSpeech[0]
                : filteredWords[0];

        if (!anotherOption) {
            console.warn('No suitable alternative translation options found.');
            setModalTranslationOptions(null);
            return;
        }

        const options = [chooseTranslationForWord, anotherOption];
        shuffle(options);

        const elems = options.map(option => {
            const isCorrect = option.id === chooseTranslationForWord.id;
            const showCursorHint =
                isCorrect && gameProgress.gameLevel === 1 && wordsFound === 0;
            return (
                <div
                    key={option.id}
                    className="flex max-w-[50%] flex-1 items-center"
                >
                    <AnswerOption
                        key={option.id}
                        word={option}
                        correctWord={chooseTranslationForWord}
                        studyLanguage={studyLanguage}
                        showCursorHint={showCursorHint}
                    />
                </div>
            );
        });

        setModalTranslationOptions(elems);
    }, [
        chooseTranslationForWord,
        words,
        gameProgress.gameLevel,
        wordsFound,
        getWordsByCategory,
        studyLanguage
    ]);

    const onSelectedWordPress = () => {
        if (!chooseTranslationForWord) return;
        setIsShowInstructionsModal(true);
    };

    const handleOptionsModalClose = () => {
        setIsOptionsModalVisible(false);
        emitter.emit('tapOutsideTheGrid');
        // Delay resetState until after the modal finishes closing
        setTimeout(() => {
            resetState();
        }, 300);
    };

    return (
        <div className="relative mb-4 min-h-16">
            {showInstructions && (
                <QuestionInstructions
                    text={getLabel('puzzle.find-words-and-choose-translation')}
                />
            )}
            <div className={questionPanelStyles.selectedWordContainer}>
                {(selectedWord || chooseTranslationForWord) && (
                    <div
                        className={questionPanelStyles.selectedWordWrapper}
                        style={
                            chooseTranslationForWord
                                ? {
                                      backgroundColor: BACKGROUND_COLOR_FOUND
                                  }
                                : {}
                        }
                    >
                        <p
                            className={questionPanelStyles.selectedWord}
                            style={{
                                ...(chooseTranslationForWord && {
                                    color: selectionColor
                                })
                            }}
                        >
                            {selectedWord ||
                                toUpperCaseWord(chooseTranslationForWord?.word)}
                        </p>
                    </div>
                )}
            </div>

            <ChooseTranslationModal
                isVisible={isOptionsModalVisible}
                onClose={handleOptionsModalClose}
            >
                <div className="my-4">
                    <div
                        className="mb-5 flex justify-center"
                        onClick={onSelectedWordPress}
                    >
                        <motion.div
                            className="rounded-xl bg-grey500/50 px-2 py-0.5"
                            style={
                                chooseTranslationForWord
                                    ? {
                                          backgroundColor:
                                              BACKGROUND_COLOR_FOUND
                                      }
                                    : {}
                            }
                            animate={{
                                scale: [1, 1.05, 1]
                            }}
                            transition={{
                                duration: 2,
                                repeat: Infinity,
                                repeatType: 'reverse'
                            }}
                        >
                            <p
                                className="mx-2 text-lg font-extrabold text-white"
                                style={{
                                    ...(chooseTranslationForWord && {
                                        color: selectionColor
                                    })
                                }}
                            >
                                {selectedWord ||
                                    (chooseTranslationForWord
                                        ? toUpperCaseWord(
                                              chooseTranslationForWord.word
                                          )
                                        : '')}
                            </p>
                        </motion.div>
                    </div>
                    <div className="flex flex-wrap items-start">
                        {modalTranslationOptions}
                    </div>
                </div>
            </ChooseTranslationModal>

            <ModalInfo
                isVisible={isShowInstructionsModal}
                onClose={() => setIsShowInstructionsModal(false)}
                description={getLabel('puzzle.choose-correct-translation')}
            />
        </div>
    );
};

export default ChooseTranslationQuestionsPanel;

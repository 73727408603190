import { type FC, useEffect, useRef, useState } from 'react';
import ReactGA from 'react-ga4';
import { FaForward } from 'react-icons/fa';
import { toast } from 'react-toastify';
import Tippy from '@tippyjs/react';
import { motion } from 'framer-motion';

import { SoundName } from '@/audio/AudioManager';
import { Dialogue, ModalInfo, TooltipContent } from '@/components';
import HintButton, { HintButtonHandle } from '@/components/buttons/HintButton';
import MagicWandIcon from '@/components/icons/MagicWandIcon';
import {
    useGameConfig,
    useGameProgress,
    useLabels,
    useNavigation,
    useSettings
} from '@/context';
import emitter, { ExtraWordFoundPayload } from '@/events/emitter';
import ShopModal from '@/screens-modal/ShopModal';
import { COLORS } from '@/utils/colors';
import { WORDS_TO_COMPLETE_BUCKET } from '@/utils/constants';

import bucket from '../../../assets/images/icons/bucket.png';
import exit from '../../../assets/images/icons/exit.png';
import question from '../../../assets/images/icons/question.png';

import { PuzzleType } from './types';
import WordBox from './WordBox';

import 'tippy.js/animations/scale-subtle.css';

interface HintsPanelProps {
    puzzleType: PuzzleType;
    onLeavePuzzle: () => void;
    isReview?: boolean;
}

const isBucketFull = wordBoxWords => {
    return wordBoxWords.length >= WORDS_TO_COMPLETE_BUCKET;
};

const HintsPanel: FC<HintsPanelProps> = ({
    puzzleType,
    onLeavePuzzle,
    isReview = false
}) => {
    const { getLabel } = useLabels();
    const [isPauseDialogVisible, setIsPauseDialogVisible] = useState(false);
    const { gameConfig } = useGameConfig();
    const { settings, updateIsMagicWandTooltipNeeded } = useSettings();
    const { gameProgress, addCoins, wordBoxWords, addWordToWordBox } =
        useGameProgress();
    const { navigate } = useNavigation();

    const [isMagicWandTooltipVisible, setIsMagicWandTooltipVisible] = useState(
        gameProgress.gameLevel === 2 && settings.isMagicWandTooltipNeeded
    );

    const [isShowShop, setIsShowShop] = useState(false);
    const [isShowInstructions, setIsShowInstructions] = useState(false);
    const [isShowWordBox, setIsShowWordBox] = useState(false);
    const [extraWord, setExtraWord] = useState(null);
    const [isAnimating, setIsAnimating] = useState(false);
    const [extraWordCoordinates, setExtraWordCoordinates] = useState<
        { x: number; y: number } | undefined
    >(undefined);

    const magicWandButtonRef = useRef<HintButtonHandle>(null);

    const bucketButtonRef = useRef<HintButtonHandle>(null);

    useEffect(() => {
        const onMagicWandHintTileFound = () => {
            if (gameProgress.coins < gameConfig.magicWandHintPrice) {
                ReactGA.event({
                    category: 'Shop',
                    action: 'Open shop. Not enough coins for magic wand hint'
                });
                setIsShowShop(true);
            } else {
                emitter.emit('magicWandHintShowTile');
                addCoins(-gameConfig.magicWandHintPrice);
                magicWandButtonRef.current?.animateSpendCoins(
                    gameConfig.magicWandHintPrice
                );
            }
        };

        const onMagicWandHintCannotFind = () => {
            emitter.emit('playSound', { sound: SoundName.Wrong });
            toast(getLabel('hint.all-letters-are-found'));
        };

        emitter.on('magicWandHintTileFound', onMagicWandHintTileFound);
        emitter.on('magicWandHintCannotFind', onMagicWandHintCannotFind);

        return () => {
            emitter.off('magicWandHintTileFound', onMagicWandHintTileFound);
            emitter.off('magicWandHintCannotFind', onMagicWandHintCannotFind);
        };
    }, [gameProgress.coins]);

    useEffect(() => {
        const onExtraWordFound = (
            extraWordFoundPayload: ExtraWordFoundPayload
        ) => {
            setExtraWord(extraWordFoundPayload.word);
            setIsAnimating(true);
            setExtraWordCoordinates(extraWordFoundPayload.coordinates);

            emitter.emit('playSound', { sound: SoundName.Card });

            setTimeout(() => {
                setIsAnimating(true);
                const updatedWords = addWordToWordBox(
                    settings.studyLanguage,
                    extraWordFoundPayload.word
                );
                if (updatedWords.length >= WORDS_TO_COMPLETE_BUCKET) {
                    emitter.emit('playSound', { sound: SoundName.Notice });
                }

                if (bucketButtonRef.current && !isBucketFull(updatedWords)) {
                    bucketButtonRef.current.animate();
                }
            }, 500);
        };

        emitter.on('extraWordFound', onExtraWordFound);

        return () => {
            emitter.off('extraWordFound', onExtraWordFound);
        };
    }, [addWordToWordBox, wordBoxWords]);

    useEffect(() => {
        if (isBucketFull(wordBoxWords)) {
            bucketButtonRef.current.animateActionRequired();
        } else if (wordBoxWords.length === 0) {
            bucketButtonRef.current.stopAnimation();
        }
    }, [wordBoxWords, isShowWordBox]);

    const onPausePress = () => setIsPauseDialogVisible(true);

    const onMagicWandHintPress = () => {
        ReactGA.event({
            category: 'Hint',
            action: 'Magic wand hint pressed'
        });

        if (
            !isMagicWandTooltipVisible &&
            gameProgress.coins >= gameConfig.magicWandHintPrice
        ) {
            emitter.emit('magicWandHintRequested');
        } else if (gameProgress.coins < gameConfig.magicWandHintPrice) {
            ReactGA.event({
                category: 'Shop',
                action: 'Open shop. Not enough coins for magic wand hint'
            });
            setIsShowShop(true);
        }
    };

    const onFinishStep = () => emitter.emit('forceGameStepComplete');
    const onLeavePuzzlePress = () => onLeavePuzzle();
    const onSeeNewWordsAgainPress = () => navigate('WordsIntro');
    const onHelpPress = () => setIsShowInstructions(true);
    const onBucketPress = () => {
        ReactGA.event({
            category: 'Word Box',
            action: 'Show word box'
        });
        setIsShowWordBox(true);
    };

    const getInstructionsText = () => {
        switch (puzzleType) {
            case PuzzleType.BasicWithTranslation:
            case PuzzleType.BasicWithoutTranslation:
                return getLabel('puzzle.find-words');
            case PuzzleType.ChooseTranslation:
                return getLabel('puzzle.find-words-and-choose-translation');
            default:
                return 'Instructions are missing';
        }
    };

    return (
        <>
            {extraWord && isAnimating && (
                <motion.div
                    initial={{
                        x: extraWordCoordinates?.x,
                        y: extraWordCoordinates?.y,
                        scale: 1
                    }}
                    animate={{
                        x: bucketButtonRef.current
                            ? bucketButtonRef.current.getBoundingClientRect()
                                  .left
                            : 0,
                        y: bucketButtonRef.current
                            ? bucketButtonRef.current.getBoundingClientRect()
                                  .top
                            : 0,
                        scale: 0.4
                    }}
                    transition={{
                        duration: 0.5,
                        ease: 'easeInOut'
                    }}
                    className="fixed left-0 top-0 z-[1000] rounded-lg bg-grey/80 px-2 py-1 font-bold tracking-widest text-white"
                    onAnimationComplete={() => {
                        setExtraWord(null);
                        setIsAnimating(false);
                    }}
                >
                    {extraWord}
                </motion.div>
            )}
            {isMagicWandTooltipVisible && (
                <div className="fixed inset-0 z-10 bg-black opacity-70"></div>
            )}
            <div className="flex justify-between">
                <div className="flex gap-2">
                    <Tippy
                        content={
                            <TooltipContent
                                title={getLabel('hint.magic-wand')}
                                text={getLabel('hint.magic-wand-text')}
                            />
                        }
                        visible={isMagicWandTooltipVisible}
                        placement="top-start"
                        onClickOutside={() => {
                            setTimeout(() => {
                                setIsMagicWandTooltipVisible(false);
                                void updateIsMagicWandTooltipNeeded(false);
                            }, 100);
                        }}
                        duration={300}
                        animation="scale-subtle"
                    >
                        <div>
                            <HintButton
                                ref={magicWandButtonRef}
                                onButtonPress={onMagicWandHintPress}
                                icon={
                                    <MagicWandIcon
                                        width={36}
                                        height={36}
                                        color={COLORS.white}
                                    />
                                }
                                price={gameConfig.magicWandHintPrice}
                                className={
                                    isMagicWandTooltipVisible ? 'z-20' : ''
                                }
                            />
                        </div>
                    </Tippy>
                    {/* <Tippy
                        content={
                            <TooltipContent
                                title={getLabel('hint.audio-hint')}
                                text={getLabel('hint.audio-hint-text')}
                            />
                        }
                        visible={isAudioHintTooltipVisible}
                        placement="top-start"
                        onClickOutside={() => {
                            setIsAudioHintTooltipVisible(false);
                            void updateIsAudioHintTooltipNeeded(false);
                        }}
                        duration={300}
                        animation="scale-subtle"
                    >
                        <div>
                            <HintButton
                                ref={audioHintButtonRef}
                                onButtonPress={onAudioHintPress}
                                icon={
                                    <VolumeIcon
                                        width={22}
                                        height={22}
                                        color={COLORS.white}
                                    />
                                }
                                price={gameConfig.audioHintPrice}
                                className={
                                    isAudioHintTooltipVisible ? 'z-20' : ''
                                }
                            />
                        </div>
                    </Tippy> */}
                    {settings.isShowDevControls && !isReview && (
                        <HintButton
                            onButtonPress={onFinishStep}
                            icon={<FaForward size={20} color="white" />}
                        />
                    )}
                </div>
                <div className="flex gap-2">
                    <HintButton
                        ref={bucketButtonRef}
                        onButtonPress={onBucketPress}
                        icon={<img src={bucket} className="h-5" />}
                        badge={wordBoxWords.length}
                    />
                    <HintButton
                        onButtonPress={onHelpPress}
                        icon={<img src={question} className="h-5" />}
                    />
                    <HintButton
                        onButtonPress={onPausePress}
                        icon={<img src={exit} className="h-5" />}
                    />
                </div>
            </div>
            <ShopModal
                isVisible={isShowShop}
                onClose={() => setIsShowShop(false)}
                notEnoughCoinsMode={true}
            />
            <WordBox
                isVisible={isShowWordBox}
                onClose={() => setIsShowWordBox(false)}
            />
            <ModalInfo
                isVisible={isShowInstructions}
                onClose={() => setIsShowInstructions(false)}
                description={getInstructionsText()}
            />
            <Dialogue
                isVisible={isPauseDialogVisible}
                onClose={() => setIsPauseDialogVisible(false)}
                title={getLabel('puzzle.game-on-hold')}
                text={
                    isReview
                        ? getLabel('puzzle.are-you-sure')
                        : getLabel('puzzle.if-you-leave')
                }
                onButton1Press={onLeavePuzzlePress}
                onButton2Press={isReview ? undefined : onSeeNewWordsAgainPress}
                button1Text={getLabel('puzzle.leave-this-puzzle')}
                button2Text={
                    isReview
                        ? undefined
                        : getLabel('puzzle.see-new-words-again')
                }
            />
        </>
    );
};

export default HintsPanel;

import { useEffect } from 'react';
import { closingBehavior } from '@telegram-apps/sdk-react';

const useEnableClosingConfirmation = () => {
    useEffect(() => {
        closingBehavior.enableConfirmation();
    }, []);
};

export default useEnableClosingConfirmation;

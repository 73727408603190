import { type FC, useEffect, useRef, useState } from 'react';

import emitter from '@/events/emitter';
import { BoardData } from '@/puzzle/types/BoardData';

import QuestionsPanel from './questionsPanels/QuestionsPanel';
import PuzzleBoard from './PuzzleBoard';
import { PuzzleData } from './types';

type GameStepProps = {
    puzzleData: PuzzleData;
    studyLanguage: string;
    onAllWordsFound: () => void;
    previousBoardData?: BoardData;
    onBoardDataReady?: (boardData: BoardData) => void;
    showCursor: boolean;
};

const GameStep: FC<GameStepProps> = ({
    puzzleData,
    studyLanguage,
    onAllWordsFound,
    previousBoardData,
    onBoardDataReady,
    showCursor
}) => {
    const [wordsFound, setWordsFound] = useState(0);
    const [forceWordsFound, setForceWordsFound] = useState(false);
    const hasCalledOnAllWordsFound = useRef(false);

    useEffect(() => {
        const correctAnswerGiven = () => {
            setWordsFound(prev => prev + 1);
        };

        const forceGameStepComplete = () => {
            setForceWordsFound(true);
        };

        emitter.on('correctAnswerGiven', correctAnswerGiven);
        emitter.on('forceGameStepComplete', forceGameStepComplete);

        return () => {
            emitter.off('correctAnswerGiven', correctAnswerGiven);
            emitter.off('forceGameStepComplete', forceGameStepComplete);
        };
    }, []);

    useEffect(() => {
        if (
            (wordsFound === puzzleData.wordsToPlay.length || forceWordsFound) &&
            !hasCalledOnAllWordsFound.current
        ) {
            hasCalledOnAllWordsFound.current = true;

            if (forceWordsFound) {
                console.log(
                    'GameStep calling onAllWordsFound immediately due to forceWordsFound'
                );
                onAllWordsFound();
            } else {
                const delay = 750;
                setTimeout(() => {
                    console.log('GameStep calling onAllWordsFound after delay');
                    onAllWordsFound();
                }, delay);
            }
        }
    }, [wordsFound, forceWordsFound, puzzleData, onAllWordsFound]);

    return (
        <>
            <QuestionsPanel
                puzzleData={puzzleData}
                studyLanguage={studyLanguage}
                wordsFound={wordsFound}
            />
            <PuzzleBoard
                puzzleData={puzzleData}
                studyLanguage={studyLanguage}
                previousBoardData={previousBoardData}
                onBoardDataReady={onBoardDataReady}
                showCursor={showCursor}
            />
        </>
    );
};

export default GameStep;

import { type FC, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { useAsyncFn } from 'react-use';

import LanguageButton from '@/components/buttons/LanguageButton';
import TextButton from '@/components/buttons/TextButton';
import CloseIcon from '@/components/icons/CloseIcon';
import PanelWithText from '@/components/PanelWithText';
import { useLabels, useNavigation, useSettings } from '@/context';
import { commonStyles } from '@/styles/commonStyles';
import { getUniqueAvailableUILanguages } from '@/utils/languages';

const ChooseUILanguageScreen: FC = () => {
    const { navigate } = useNavigation();
    const { settings, setUILanguage } = useSettings();

    const [selectedLanguageCode, setSelectedLanguageCode] = useState<
        string | null
    >(settings.uiLanguage);
    const [isNavigating, setIsNavigating] = useState(false);

    const uiLanguages = getUniqueAvailableUILanguages();
    const { getLabelForLanguage } = useLabels();

    // Determine the current UI language code to use for labels
    const currentUILanguageCode = selectedLanguageCode ?? settings.uiLanguage;

    const onLanguageButtonPress = (languageCode: string) => {
        if (selectedLanguageCode === languageCode) {
            setSelectedLanguageCode(null); // Unselect if the same language is selected
        } else {
            setSelectedLanguageCode(languageCode); // Select the new language
        }
    };

    // useAsyncFn for continue button
    const [continueState, doContinue] = useAsyncFn(async () => {
        if (!selectedLanguageCode) {
            throw new Error('No language selected');
        }
        await setUILanguage(selectedLanguageCode);
        setIsNavigating(true);
        navigate('ChooseStudyLanguage');
    }, [selectedLanguageCode]);

    // Handle errors during continue
    useEffect(() => {
        if (continueState.error) {
            toast.error(
                getLabelForLanguage(
                    'error.failed-to-set-language',
                    currentUILanguageCode
                )
            );
        }
    }, [continueState.error, getLabelForLanguage, currentUILanguageCode]);

    const onContinuePress = () => {
        if (continueState.loading) {
            return;
        }

        doContinue();
    };

    const onClosePress = () => {
        if (continueState.loading || isLoading) {
            return;
        }

        navigate('ChooseStudyLanguage');
    };

    const isLoading = continueState.loading || isNavigating;

    return (
        <div className={commonStyles.languageSelectionScreen}>
            <button
                onClick={onClosePress}
                className="z-10 self-end p-4 pr-1 text-white"
                disabled={isLoading}
            >
                <CloseIcon height={16} width={16} color="white" />
            </button>
            <div className="mb-2.5 mt-1 flex flex-1 flex-col">
                <div>
                    <PanelWithText
                        text={getLabelForLanguage(
                            'onboarding.courses-available-in',
                            currentUILanguageCode
                        )}
                        className="mb-8"
                    />
                    <div className="overflow-y-auto">
                        {uiLanguages.map(uiLanguage => (
                            <div key={uiLanguage.languageCode} className="mb-4">
                                <LanguageButton
                                    language={uiLanguage}
                                    isSelected={
                                        selectedLanguageCode ===
                                        uiLanguage.languageCode
                                    }
                                    onPress={() => {
                                        if (isLoading) {
                                            return;
                                        }

                                        onLanguageButtonPress(
                                            uiLanguage.languageCode
                                        );
                                    }}
                                />
                            </div>
                        ))}
                    </div>
                </div>
            </div>
            <TextButton
                onClick={onContinuePress}
                text={getLabelForLanguage(
                    'button.continue',
                    currentUILanguageCode
                )}
                isDisabled={!selectedLanguageCode}
                isLoading={isLoading}
            />
        </div>
    );
};

export default ChooseUILanguageScreen;

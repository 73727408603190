import { useEffect, useMemo, useState } from 'react';
import { IoSearchOutline } from 'react-icons/io5';
import Fuse, { FuseResult } from 'fuse.js';

import useDisableVerticalSwipe from '@/hooks/useDisableVerticalSwipe';
import { filterOutLockedStampCollectionCategories } from '@/utils/categories';

import { HeaderWithCloseAndBackButton } from '../../components';
import { useGameProgress } from '../../context/GameProgressContext';
import { useLabels } from '../../context/LabelsContext';
import { useNavigation } from '../../context/NavigationContext';
import { useProfile } from '../../context/ProfileContext';
import { CategoryInfoWithProgress } from '../../types/category';

import { CategoryListItem } from './components/CategoryListItem';

const CategoriesScreen = () => {
    useDisableVerticalSwipe();
    const [searchQuery, setSearchQuery] = useState('');
    const [searchResults, setSearchResults] = useState<
        FuseResult<CategoryInfoWithProgress>[]
    >([]);

    const { getLabel } = useLabels();
    const { navigateToProfile, closeProfile } = useNavigation();
    const { chooseCategory } = useProfile();
    const { gameProgress } = useGameProgress();

    const categories = useMemo(
        () =>
            filterOutLockedStampCollectionCategories(
                gameProgress.categoriesProgress
            ),
        [gameProgress.categoriesProgress]
    );

    const sortAlphabetically = (
        a: CategoryInfoWithProgress,
        b: CategoryInfoWithProgress
    ) => {
        const nameA = a.categoryInfo.name.toLowerCase();
        const nameB = b.categoryInfo.name.toLowerCase();

        return nameA.localeCompare(nameB);
    };

    const renderCategoryItem = (item: CategoryInfoWithProgress) => {
        const categoryWithProgress = gameProgress.categoriesProgress.find(
            category => category.categoryInfo.id === item.categoryInfo.id
        );

        const wordsLearnt =
            categoryWithProgress.categoryProgressItem.wordProgressItems.length;

        const totalWords = item.categoryInfo.words.length;

        const phrasesLearnt =
            categoryWithProgress.categoryProgressItem.phraseProgressItems
                .length;

        const totalPhrases = item.categoryInfo.phrases.length;

        const totalItemsLearnt = wordsLearnt + phrasesLearnt;
        const totalItems = totalWords + totalPhrases;

        const progress = totalItems > 0 ? totalItemsLearnt / totalItems : 0;

        return (
            <CategoryListItem
                item={item}
                chooseCategory={chooseCategory}
                totalItemsLearnt={totalItemsLearnt}
                totalItems={totalItems}
                progress={progress}
            />
        );
    };

    const fuseOptions = {
        keys: ['categoryInfo.name'],
        threshold: 0.3
    };

    const fuse = new Fuse(categories, fuseOptions);

    useEffect(() => {
        const results = fuse.search(searchQuery);
        setSearchResults(results);
    }, [searchQuery]);

    const filterAndSort = (categories: CategoryInfoWithProgress[]) => {
        return categories
            .filter(item => item.categoryInfo.languageLevel === 'Beginner')
            .sort(sortAlphabetically);
    };

    return (
        <div className="flex h-full flex-col">
            <HeaderWithCloseAndBackButton
                onClose={() => closeProfile()}
                onBack={() => navigateToProfile('Account')}
                backTitle={getLabel('common.back')}
            />
            <div className="flex items-center rounded-2xl bg-grey600 p-2">
                <IoSearchOutline size={20} className="mr-2 text-gray-400" />
                <input
                    type="text"
                    placeholder={getLabel('common.search-vocabulary-topics')}
                    value={searchQuery}
                    onChange={e => setSearchQuery(e.target.value)}
                    className="flex-1 bg-transparent text-white outline-none"
                />
            </div>
            <div className="mt-5 max-h-[calc(100vh-130px)] overflow-y-scroll pb-6">
                {filterAndSort(
                    searchQuery
                        ? searchResults.map(item => item.item)
                        : categories
                ).map(item => (
                    <div key={item.categoryInfo.id}>
                        {renderCategoryItem(item)}
                    </div>
                ))}
            </div>
        </div>
    );
};

export default CategoriesScreen;

import { FC, useMemo, useState } from 'react';
import LottiePlayer from 'react-lottie-player';
import { toast } from 'react-toastify';
import { useAsyncFn } from 'react-use';

import { SoundName } from '@/audio/AudioManager';
import { Panel, TextButton } from '@/components';
import {
    useGameConfig,
    useGameProgress,
    useLabels,
    useNavigation,
    useSettings
} from '@/context';
import emitter from '@/events/emitter';
import { resultScreenStyles } from '@/styles/resultScreenStyles';
import { dayPluralizer } from '@/utils/dayPluralizer';

import coinIcon from '../../../assets/images/icons/coin.png';
import streakAnimation from '../../../assets/lottie/FireIcon.json';

const StreakScreen: FC = () => {
    const { getLabel } = useLabels();
    const { streak } = useGameProgress();
    const { navigate } = useNavigation();
    const { settings } = useSettings();

    // store a reference to avoid re-rendering after the reward is claimed
    const originalStreak = useMemo(() => {
        return streak;
    }, []);

    const [isNavigating, setIsNavigating] = useState(false);

    const { claimStreakReward } = useGameProgress();
    const { getStreakReward } = useGameConfig();

    const currentDayLabel = dayPluralizer(
        originalStreak.currentStreak,
        getLabel,
        settings.uiLanguage
    );

    const [handleClaimRewardState, doHandleClaimRewardUnlock] =
        useAsyncFn(async () => {
            try {
                await claimStreakReward();
                emitter.emit('playSound', { sound: SoundName.Coin });
                setIsNavigating(true);
                navigate('ChooseCategory');
            } catch (error) {
                console.error('Error claiming streak reward:', error);
                toast.error(getLabel('error.failed-to-claim-streak-reward'));
                throw error;
            }
        }, [navigate, claimStreakReward]);

    const onClaimRewardPress = () => {
        doHandleClaimRewardUnlock();
    };

    const isLoading = handleClaimRewardState.loading || isNavigating;

    return (
        <div className="flex h-full flex-col justify-center">
            <Panel
                className={`${resultScreenStyles.panel} flex flex-col items-center`}
            >
                <h1>{getLabel('streak')}</h1>

                {/* Lottie Animation */}
                <div className="mt-4 flex justify-center">
                    <LottiePlayer
                        loop
                        animationData={streakAnimation}
                        play
                        style={{ width: 150, height: 150 }}
                    />
                </div>

                {/* Current Streak */}
                <div className="mb-2 mt-2 text-center">
                    <span className="text-4xl font-bold text-orange">
                        {originalStreak.currentStreak} {currentDayLabel}
                    </span>
                </div>

                {/* Reward */}
                <div className="flex w-full items-center justify-center">
                    <span className="mr-1 text-xl text-orange">
                        {getLabel('streak.reward')}:
                    </span>
                    <span className="mr-1 text-xl font-bold text-orange">
                        {getStreakReward(originalStreak.currentStreak)}
                    </span>
                    <img src={coinIcon} alt="Coin Icon" className="h-6 w-6" />
                </div>

                {/* Next Reward */}
                <div className="mb-2 mt-4 flex w-full items-center justify-center">
                    <span className="mr-1 text-lg text-gray-400">
                        {getLabel('streak.next-reward')}:
                    </span>
                    <span className="mr-1 text-lg font-bold text-orange">
                        {getStreakReward(originalStreak.currentStreak + 1)}
                    </span>
                    <img src={coinIcon} alt="Coin Icon" className="h-5 w-5" />
                </div>
            </Panel>
            <TextButton
                onClick={onClaimRewardPress}
                text={getLabel('common.claim-reward')}
                isDisabled={isLoading}
                isLoading={isLoading}
            />
        </div>
    );
};

export default StreakScreen;

import { type FC, ReactNode } from 'react';
import classNames from 'classnames';
import { motion } from 'framer-motion';

import { SoundName } from '@/audio/AudioManager';
import emitter from '@/events/emitter';
import { useVibration } from '@/hooks/useVibration';

import LoadingCircle from './LoadingCircle';

interface TextButtonProps {
    onClick: () => void;
    text?: string;
    children?: ReactNode;
    isDisabled?: boolean;
    className?: string;
    bgColor?: string;
    textStyle?: string;
    showOverlay?: boolean;
    verticalPadding?: string;
    horizontalPadding?: string;
    isLoading?: boolean;
    textSizeStyle?: string;
}

const TextButton: FC<TextButtonProps> = ({
    text,
    children,
    onClick,
    isDisabled,
    className = '',
    bgColor = '',
    textStyle = '',
    verticalPadding = 'py-3',
    horizontalPadding = 'px-4',
    isLoading,
    textSizeStyle = 'text-lg'
}) => {
    const { vibrateLight } = useVibration();

    const handlePress = () => {
        vibrateLight();
        emitter.emit('playSound', { sound: SoundName.Click2 });
        onClick();
    };

    const getBgColor = () => {
        if (isDisabled) {
            return 'bg-grey400';
        }
        if (bgColor) {
            return bgColor;
        }
        return 'bg-white';
    };

    const getShadow = () => {
        if (bgColor.includes('grey')) {
            return 'shadow-dark';
        }
        return 'shadow-light';
    };

    const textColorClassName = classNames(
        isDisabled ? 'text-grey300' : 'text-grey500',
        textStyle
    );

    return (
        <>
            <motion.button
                whileTap={{ scale: 0.95 }}
                initial={{ scale: 1 }}
                animate={{ scale: 1 }}
                onClick={handlePress}
                disabled={isDisabled}
                className={classNames(
                    'relative rounded-3xl',
                    horizontalPadding,
                    verticalPadding,
                    getBgColor(),
                    getShadow(),
                    isDisabled && 'cursor-not-allowed opacity-90',
                    className
                )}
            >
                {isLoading ? (
                    <LoadingCircle colorClassName={textColorClassName} />
                ) : (
                    <span
                        className={classNames(
                            'text-center font-nunito font-extraBold uppercase tracking-wide',
                            textColorClassName,
                            textSizeStyle
                        )}
                    >
                        {text ?? children}
                    </span>
                )}
            </motion.button>
        </>
    );
};

export default TextButton;

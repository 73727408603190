import {
    createContext,
    FC,
    ReactNode,
    useCallback,
    useContext,
    useMemo,
    useState
} from 'react';

import { getGameConfigApi } from '@/api/client';
import { GameConfig } from '@/types/settings';

interface GameConfigContextType {
    gameConfig: GameConfig | null;
    getStreakReward: (streak: number) => number;
    loadGameConfig: (onLoadingFinished: () => void) => Promise<void>;
}

interface GameConfigProviderProps {
    children: ReactNode;
}

const GameConfigContext = createContext<GameConfigContextType | undefined>(
    undefined
);

export const GameConfigProvider: FC<GameConfigProviderProps> = ({
    children
}) => {
    const [gameConfig, setGameConfig] = useState<GameConfig | null>(null);

    const loadGameConfig = useCallback(
        async (onLoadingFinished: () => void) => {
            try {
                const loadedConfig = await getGameConfigApi();
                console.log('Loaded game config:', loadedConfig);
                setGameConfig(loadedConfig);
                onLoadingFinished();
            } catch (error) {
                console.error('Failed to load game config', error);
                throw error;
            }
        },
        []
    );

    const getStreakReward = useCallback(
        (streak: number) => {
            if (!gameConfig) {
                console.error(
                    'Game config is not loaded. Cannot calculate streak reward.'
                );
                return 0;
            }

            const rewards = gameConfig.streakRewards;
            const reward = rewards.find(
                streakReward => streakReward.streak === streak
            );

            return reward ? reward.reward : rewards[rewards.length - 1].reward;
        },
        [gameConfig]
    );

    const contextValue = useMemo(
        () => ({
            gameConfig,
            getStreakReward,
            loadGameConfig
        }),
        [gameConfig, loadGameConfig]
    );

    return (
        <GameConfigContext.Provider value={contextValue}>
            {children}
        </GameConfigContext.Provider>
    );
};

export const useGameConfig = (): GameConfigContextType => {
    const context = useContext(GameConfigContext);
    if (context === undefined) {
        throw new Error(
            'useGameConfig must be used within a GameConfigProvider'
        );
    }
    return context;
};

import { FC, useEffect, useRef, useState } from 'react';
import classNames from 'classnames';

import { SoundName } from '@/audio/AudioManager';
import emitter from '@/events/emitter';
import { useVibration } from '@/hooks/useVibration';
import PhraseWordGridHandler from '@/puzzle/PhraseWordGridHandler';
import { PhraseInfoLocalized } from '@/types/category';
import { MAX_TILE_SIZE } from '@/utils/constants';

import PhraseQuestion from './questions/PhraseQuestion';

type PhraseGameStepProps = {
    phrase: PhraseInfoLocalized;
    onAnswerFound: (phrase: PhraseInfoLocalized) => void;
};

const PhraseGameStep: FC<PhraseGameStepProps> = ({ phrase, onAnswerFound }) => {
    const { vibrateLight } = useVibration();
    const [forceAnswerFound, setForceAnswerFound] = useState(false);
    const hasCalledOnAllPhrasesFound = useRef(false);
    const [answerFound, setAnswerFound] = useState(false);

    const rootContainerRef = useRef<HTMLDivElement>(null);
    const [layoutSet, setLayoutSet] = useState(false);

    const [options, setOptions] = useState<string[]>([]);
    const [containerWidth, setContainerWidth] = useState<number>(0);
    const [containerHeight, setContainerHeight] = useState<number>(0);
    const [tileSize, setTileSize] = useState<number>(0);

    // Generate shuffled options: 1 correct + 3 wrong
    const generateShuffledOptions = (): string[] => {
        const correctWord = phrase.word;
        const wrongOptions = phrase.wrongOptions.slice(); // Clone to prevent mutation

        // Ensure there are at least 3 wrong options
        const selectedWrongOptions =
            wrongOptions.length >= 3
                ? wrongOptions.sort(() => 0.5 - Math.random()).slice(0, 3)
                : wrongOptions;

        const combinedOptions = [correctWord, ...selectedWrongOptions];

        // Shuffle the combined options
        for (let i = combinedOptions.length - 1; i > 0; i--) {
            const j = Math.floor(Math.random() * (i + 1));
            [combinedOptions[i], combinedOptions[j]] = [
                combinedOptions[j],
                combinedOptions[i]
            ];
        }

        return combinedOptions;
    };

    useEffect(() => {
        setOptions(generateShuffledOptions());
    }, [phrase]);

    // Only support vertical layout
    const isVerticalLayout = true;
    const numberOfColumns = 4; // Assuming 4 columns for vertical layout

    useEffect(() => {
        const handleCorrectPhraseAnswer = () => {
            emitter.emit('playSound', { sound: SoundName.Success });
            emitter.emit('playAudioByUrl', { audioUrl: phrase.audioUrl });
            vibrateLight();
            setAnswerFound(true);
        };

        const handleForceGameStepComplete = () => {
            setForceAnswerFound(true);
        };

        emitter.on('correctPhraseAnswerGiven', handleCorrectPhraseAnswer);
        emitter.on('forceGameStepComplete', handleForceGameStepComplete);

        return () => {
            emitter.off('correctPhraseAnswerGiven', handleCorrectPhraseAnswer);
            emitter.off('forceGameStepComplete', handleForceGameStepComplete);
        };
    }, [phrase]);

    useEffect(() => {
        if (
            (answerFound || forceAnswerFound) &&
            !hasCalledOnAllPhrasesFound.current
        ) {
            hasCalledOnAllPhrasesFound.current = true;

            if (forceAnswerFound) {
                console.log(
                    'GameStep calling onAnswerFound immediately due to forceAnswerFound'
                );
                onAnswerFound(phrase);
            } else {
                setTimeout(() => {
                    console.log('GameStep calling onAnswerFound after delay');
                    onAnswerFound(phrase);
                }, 750);
            }
        }
    }, [answerFound, forceAnswerFound, onAnswerFound]);

    const handleCorrectWordComplete = () => {
        emitter.emit('correctPhraseAnswerGiven', { phrase });
    };

    useEffect(() => {
        const container = rootContainerRef.current;
        if (!container) return;

        const resizeObserver = new ResizeObserver(entries => {
            if (!layoutSet) {
                for (const entry of entries) {
                    setContainerWidth(entry.contentRect.width);
                    setContainerHeight(entry.contentRect.height);
                }
                setLayoutSet(true);
            } else {
                resizeObserver.unobserve(container);
            }
        });

        resizeObserver.observe(container);

        return () => {
            resizeObserver.unobserve(container);
        };
    }, [layoutSet]);

    useEffect(() => {
        if (
            options.length === 0 ||
            containerHeight === 0 ||
            containerWidth === 0
        )
            return;

        // Find the length of the longest option
        const longestOptionLength = Math.max(
            ...options.map(option => option.length)
        );

        // Calculate tile height based on container height and the longest option
        const calculatedTileHeight = containerHeight / longestOptionLength;

        // Tile width is the same as height
        let calculatedTileSize = Math.min(calculatedTileHeight, MAX_TILE_SIZE);

        // Ensure that the total width of tiles does not exceed container width
        const maxTileWidthBasedOnContainer = containerWidth / numberOfColumns;
        calculatedTileSize = Math.min(
            calculatedTileSize,
            maxTileWidthBasedOnContainer
        );

        setTileSize(calculatedTileSize);
        console.log(`Tile size set to: ${calculatedTileSize}px`);
    }, [options, containerWidth, containerHeight, isVerticalLayout]);

    return (
        <div className="flex h-full flex-col">
            <PhraseQuestion phrase={phrase} answerFound={answerFound} />
            <div className="flex flex-1 items-center justify-center p-4">
                <div
                    ref={rootContainerRef}
                    className={classNames(
                        'grid h-full w-full justify-between gap-4',
                        {
                            'grid-cols-4': isVerticalLayout
                            // 'grid-rows-4': !isVerticalLayout, // Removed horizontal layout support
                        }
                    )}
                    style={{
                        gridTemplateColumns: isVerticalLayout
                            ? `repeat(${numberOfColumns}, ${tileSize}px)`
                            : 'none' // Not needed since horizontal layout is not supported
                    }}
                >
                    {options.map((option, idx) => (
                        <PhraseWordGridHandler
                            key={`${phrase.id}-${option}-${idx}`}
                            word={option}
                            isCorrectAnswer={
                                option.toLocaleLowerCase() ===
                                phrase.word.toLocaleLowerCase()
                            }
                            categoryColor={phrase.categoryColor}
                            onCorrectWordComplete={handleCorrectWordComplete}
                            vertical={isVerticalLayout}
                            tileSize={tileSize}
                        />
                    ))}
                </div>
            </div>
        </div>
    );
};

export default PhraseGameStep;

import {
    createContext,
    type FC,
    ReactNode,
    useContext,
    useReducer
} from 'react';

import {
    CategoryInfoWithProgress,
    PhraseInfoLocalized
} from '@/types/category';
import { TOTAL_STEPS_PER_PHRASE_GAME_SESSION } from '@/utils/constants';

import { useGameProgress } from './GameProgressContext';

interface NewPhrasesGameSession {
    isReadyToPlay: boolean;
    category: CategoryInfoWithProgress | null;
    phrasesToStudy: PhraseInfoLocalized[];
    currentStep: number;
    earnedCoins: number;
    totalSteps: number;
    finished: boolean;
    learnedPhrases: PhraseInfoLocalized[];
}

interface NewPhrasesSessionContextType {
    resetGameSession: () => void;
    gameSession: NewPhrasesGameSession;
    initGameSession: (
        category: CategoryInfoWithProgress,
        phrasesToStudy: PhraseInfoLocalized[]
    ) => void;
    goToNextGameSessionStep: () => void;
    updateGameSessionCoins: (coins: number) => void;
    updateGameSessionPhrases: (learnedPhrases: PhraseInfoLocalized[]) => void;
}

const NEW_PHRASES_GAME_SESSION: NewPhrasesGameSession = {
    isReadyToPlay: false,
    category: null,
    phrasesToStudy: [],
    currentStep: 1,
    totalSteps: TOTAL_STEPS_PER_PHRASE_GAME_SESSION,
    earnedCoins: 0,
    finished: false,
    learnedPhrases: []
};

// 4. Define Action Types
type Action =
    | { type: 'RESET' }
    | {
          type: 'INIT';
          payload: {
              category: CategoryInfoWithProgress;
              phrasesToStudy: PhraseInfoLocalized[];
          };
      }
    | { type: 'NEXT_STEP' }
    | { type: 'COMPLETE_SESSION' }
    | { type: 'UPDATE_COINS'; payload: number }
    | {
          type: 'UPDATE_LEARNED_PHRASES';
          payload: PhraseInfoLocalized[];
      };

const gameSessionReducer = (
    state: NewPhrasesGameSession,
    action: Action
): NewPhrasesGameSession => {
    switch (action.type) {
        case 'RESET':
            return NEW_PHRASES_GAME_SESSION;
        case 'INIT':
            return {
                ...state,
                isReadyToPlay: true,
                category: action.payload.category,
                phrasesToStudy: action.payload.phrasesToStudy
            };
        case 'NEXT_STEP':
            return {
                ...state,
                currentStep: state.currentStep + 1
            };
        case 'COMPLETE_SESSION':
            return {
                ...state,
                finished: true,
                learnedPhrases: state.phrasesToStudy
            };
        case 'UPDATE_COINS':
            return {
                ...state,
                earnedCoins: state.earnedCoins + action.payload
            };
        case 'UPDATE_LEARNED_PHRASES':
            return {
                ...state,
                learnedPhrases: action.payload
            };
        default:
            return state;
    }
};

const NewPhrasesSessionContext = createContext<
    NewPhrasesSessionContextType | undefined
>(undefined);

interface NewPhrasesSessionProviderProps {
    children: ReactNode;
}

export const NewPhrasesSessionProvider: FC<NewPhrasesSessionProviderProps> = ({
    children
}) => {
    const { resetCategories } = useGameProgress();
    const [gameSession, dispatch] = useReducer(
        gameSessionReducer,
        NEW_PHRASES_GAME_SESSION
    );

    // Action: Complete Game Session
    const completeGameSession = () => {
        console.log('completeGameSession');
        resetCategories();

        dispatch({ type: 'COMPLETE_SESSION' });
    };

    // Action: Go to Next Step
    const goToNextGameSessionStep = () => {
        if (gameSession.currentStep + 1 > gameSession.totalSteps) {
            return completeGameSession();
        }

        dispatch({ type: 'NEXT_STEP' });
    };

    // Action: Update Earned Coins
    const updateGameSessionCoins = (coins: number) => {
        dispatch({ type: 'UPDATE_COINS', payload: coins });
    };

    // Action: Initialize Game Session
    const initGameSession = (
        category: CategoryInfoWithProgress,
        phrasesToStudy: PhraseInfoLocalized[]
    ) => {
        dispatch({
            type: 'INIT',
            payload: {
                category,
                phrasesToStudy
            }
        });
    };

    // Action: Reset Game Session
    const resetGameSession = () => {
        dispatch({ type: 'RESET' });
    };

    // Action: Update Learned Phrases
    const updateGameSessionPhrases = (
        learnedPhrases: PhraseInfoLocalized[]
    ) => {
        dispatch({ type: 'UPDATE_LEARNED_PHRASES', payload: learnedPhrases });
    };

    return (
        <NewPhrasesSessionContext.Provider
            value={{
                resetGameSession,
                gameSession,
                initGameSession,
                goToNextGameSessionStep,
                updateGameSessionCoins,
                updateGameSessionPhrases
            }}
        >
            {children}
        </NewPhrasesSessionContext.Provider>
    );
};

// 8. Create the Custom Hook
export const useNewPhrasesGameSession = (): NewPhrasesSessionContextType => {
    const context = useContext(NewPhrasesSessionContext);
    if (!context) {
        throw new Error(
            'useNewPhrasesGameSession must be used within a NewPhrasesSessionProvider'
        );
    }
    return context;
};

import { type FC } from 'react';

import Modal from '@/components/Modal';

import TextButton from './buttons/TextButton';
import CloseIcon from './icons/CloseIcon';

interface DialogProps {
    isVisible: boolean;
    onButton1Press: () => void;
    onButton2Press?: () => void;
    onClose: () => void;
    title?: string;
    text?: string;
    button1Text: string;
    button2Text?: string;
    outline?: boolean;
    button1Loading?: boolean;
}

const Dialog: FC<DialogProps> = ({
    isVisible,
    onButton1Press,
    onButton2Press,
    onClose,
    title,
    text,
    button1Text,
    button2Text,
    outline,
    button1Loading = false
}) => {
    const handleClose = () => {
        onClose();
    };

    return (
        <Modal isVisible={isVisible} onClose={onClose} outline={outline}>
            <div className="flex items-start justify-between">
                <h2>{title}</h2>
                <button
                    onClick={handleClose}
                    aria-label="Close"
                    className="mt-2 pb-3 pl-3"
                >
                    <CloseIcon height={16} width={16} color="white" />
                </button>
            </div>
            {text && (
                <p className="mt-4 text-base leading-6 text-white500">{text}</p>
            )}
            <div className="mb-3 mt-6 flex flex-col gap-2.5">
                <TextButton
                    onClick={button1Loading ? undefined : onButton1Press}
                    text={button1Text}
                    isLoading={button1Loading}
                    isDisabled={button1Loading}
                />
                {button2Text && onButton2Press && (
                    <TextButton
                        className="mt-2"
                        onClick={onButton2Press}
                        text={button2Text}
                        isDisabled={button1Loading}
                    />
                )}
            </div>
        </Modal>
    );
};

export default Dialog;

import { type FC, useEffect, useRef, useState } from 'react';
import { IoIosArrowForward, IoMdCheckmark } from 'react-icons/io';
import classNames from 'classnames';
import { mix } from 'polished';

import { useLabels, useNavigation } from '@/context';
import { useVibration } from '@/hooks/useVibration';
import { CategoryInfoWithProgress } from '@/types/category';

interface CategoryCardProps {
    item: CategoryInfoWithProgress;
    chooseCategory: (category: CategoryInfoWithProgress) => void;
    totalItemsLearnt: number;
    totalItems: number;
    progress: number;
}

export const CategoryListItem: FC<CategoryCardProps> = ({
    item,
    chooseCategory,
    totalItemsLearnt,
    totalItems,
    progress
}) => {
    const [height, setHeight] = useState(0);
    const ref = useRef<HTMLDivElement>(null);
    const { vibrateLight } = useVibration();

    useEffect(() => {
        if (ref.current) {
            setHeight(ref.current.clientWidth);
        }
    }, [ref]);

    const { navigateToProfile } = useNavigation();
    const { getLabel } = useLabels();
    const itemInfo = item.categoryInfo;

    const handleClick = () => {
        vibrateLight();
        chooseCategory(item);
        navigateToProfile('CategoryInfo');
    };

    return (
        <div className="mb-10 flex cursor-pointer" onClick={handleClick}>
            <div
                className="relative w-1/4 rounded-3xl p-5"
                ref={ref}
                style={{
                    backgroundColor:
                        progress === 1
                            ? mix(0.4, itemInfo.color, '#000')
                            : itemInfo.color,
                    height: height
                }}
            >
                {progress === 1 && (
                    <div className="absolute inset-0 flex items-center justify-center rounded-3xl bg-black/40">
                        <IoMdCheckmark size={24} color="#fff" />
                    </div>
                )}
                <img
                    src={itemInfo.imageUrl}
                    className={classNames(
                        'h-full w-full object-contain',
                        progress === 1 && 'opacity-30'
                    )}
                    alt={itemInfo.name}
                />
            </div>
            <div className="flex w-3/4 flex-col justify-between pl-5">
                <div className="flex items-center justify-between pt-1">
                    <h2 className="text-base font-bold text-white">
                        {itemInfo.name}
                    </h2>
                    <IoIosArrowForward size={20} color="#fff" />
                </div>
                <div className="self-start rounded-full bg-black/20 px-4 pb-0.5">
                    <span className="text-[11px] uppercase text-white">
                        {getLabel(
                            `level.${itemInfo.languageLevel.toLowerCase()}`
                        )}
                    </span>
                </div>
                {/* Aggregated Progress Bar */}
                <div className="relative mt-2 h-4 rounded-lg bg-white/15">
                    <div
                        className="absolute h-full rounded-lg bg-orange/85"
                        style={{ width: `${Math.round(progress * 100)}%` }}
                    />
                    <span className="absolute inset-0 flex items-center justify-center text-xs font-extrabold text-white">
                        {`${totalItemsLearnt}/${totalItems}`}
                    </span>
                </div>
            </div>
        </div>
    );
};

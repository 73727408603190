import { FC } from 'react';
import LottiePlayer from 'react-lottie-player';

import { SoundName } from '@/audio/AudioManager';
import CloseIcon from '@/components/icons/CloseIcon';
import Modal from '@/components/Modal';
import {
    useGameConfig,
    useGameProgress,
    useLabels,
    useSettings
} from '@/context';
import emitter from '@/events/emitter';
import { useVibration } from '@/hooks/useVibration';
import { commonStyles } from '@/styles/commonStyles';
import { dayPluralizer } from '@/utils/dayPluralizer';

import coinIcon from '../../assets/images/icons/coin.png';
import streakAnimation from '../../assets/lottie/FireIcon.json';

interface StreakModalProps {
    isVisible: boolean;
    onClose: () => void;
}

const StreakModal: FC<StreakModalProps> = ({ isVisible, onClose }) => {
    const { getLabel } = useLabels();
    const { vibrateLight } = useVibration();
    const { streak } = useGameProgress();
    const { getStreakReward } = useGameConfig();
    const { settings } = useSettings();

    const currentDayLabel = dayPluralizer(
        streak.currentStreak,
        getLabel,
        settings.uiLanguage
    );

    const maxDayLabel = dayPluralizer(
        streak.maxStreak,
        getLabel,
        settings.uiLanguage
    );

    const onCloseClick = () => {
        emitter.emit('playSound', { sound: SoundName.Click2 });
        vibrateLight();
        onClose();
    };

    return (
        <Modal isVisible={isVisible} onClose={onClose} outline>
            <div className={commonStyles.modalPanel}>
                <div className="flex items-start justify-between">
                    <div>
                        <h1>{getLabel('streak')}</h1>
                    </div>
                    <button onClick={onCloseClick} className="mt-2 pb-3 pl-3">
                        <CloseIcon height={16} width={16} color="white" />
                    </button>
                </div>

                {/* Lottie Animation */}
                <div className="mt-4 flex justify-center">
                    <LottiePlayer
                        loop
                        animationData={streakAnimation}
                        play
                        style={{ width: 150, height: 150 }}
                    />
                </div>

                {/* Current Streak */}
                <div className="mt-2 text-center">
                    <span className="text-4xl font-bold text-orange">
                        {streak.currentStreak} {currentDayLabel}
                    </span>
                </div>

                {/* Maximum Streak */}
                <div className="mt-6 text-center">
                    <span className="text text-gray-400">
                        {getLabel('streak.max-streak')}: {streak.maxStreak}{' '}
                        {maxDayLabel}
                    </span>
                </div>

                {/* Next Reward */}
                <div className="mt-1 flex w-full items-center justify-center">
                    <span className="text mr-1 text-gray-400">
                        {getLabel('streak.next-reward')}:
                    </span>
                    <span className="mr-1 font-bold text-orange">
                        {getStreakReward(streak.currentStreak + 1)}
                    </span>
                    <img src={coinIcon} alt="Coin Icon" className="h-5 w-5" />
                </div>
            </div>
        </Modal>
    );
};

export default StreakModal;

export const API_URL = import.meta.env.VITE_API_URL;
export const MINI_APP_URL = import.meta.env.VITE_MINI_APP_URL;

export const MAX_TILE_SIZE = 58;
export const TOTAL_STEPS_PER_GAME_SESSION = 4;
export const TOTAL_STEPS_PER_PHRASE_GAME_SESSION = 3;
export const WORDS_PER_REVIEW_STEP = 6;
export const SUPPORT_EMAIL = 'support@lingoscapes.com';

export const WORDS_TO_COMPLETE_BUCKET = 10;

import {
    hapticFeedback,
    type ImpactHapticFeedbackStyle
} from '@telegram-apps/sdk-react';

import { useSettings } from '@/context';

type UseVibrationHook = {
    vibrateLight: (ignoreSettings?: boolean) => void;
    vibrateSoft: () => void;
    vibrateMedium: () => void;
    vibrateHeavy: () => void;
    vibrateRigid: () => void;
    vibrateSelectionChanged: () => void;
};

export const useVibration = (): UseVibrationHook => {
    const { settings } = useSettings();

    const vibrateLight = (ignoreSettings = false) => {
        if (!ignoreSettings && !settings.isVibrationOn) return;
        hapticFeedback.impactOccurred('light');
    };

    const vibrateSoft = () => vibrate('soft');
    const vibrateMedium = () => vibrate('medium');
    const vibrateHeavy = () => vibrate('heavy');
    const vibrateRigid = () => vibrate('rigid');

    const vibrate = (type: ImpactHapticFeedbackStyle) => {
        if (!settings.isVibrationOn) return;
        hapticFeedback.impactOccurred(type);
    };

    const vibrateSelectionChanged = () => {
        if (!settings.isVibrationOn) return;
        hapticFeedback.selectionChanged();
    };

    return {
        vibrateLight,
        vibrateSoft,
        vibrateHeavy,
        vibrateMedium,
        vibrateRigid,
        vibrateSelectionChanged
    };
};

import { shuffle } from '@/utils/array';
import { Random } from '@/utils/Random';

import {
    Board,
    Cell,
    CellUndo,
    Placement,
    PlacementType,
    PVUndo,
    Undo,
    UndoType
} from './types/BoardCreator';
import { BoardData } from './types/BoardData';
import { LevelData } from './types/LevelData';
import { printBoardDataCells } from './print-puzzle';

const random = new Random();

const createInitialBoardData = (
    levelData: LevelData,
    previousBoardData?: BoardData
): BoardData => {
    // try to avoid putting two long words in the same row
    const longWordLength = 7;

    const cols =
        levelData.MinWordLength > longWordLength
            ? levelData.MinWordLength
            : longWordLength;
    const rows = Math.max(levelData.Words.length, levelData.MaxWordLength + 2);

    return create(levelData.Words, rows, cols, previousBoardData);
};

const createBoardData = (
    words: string[],
    rows: number,
    cols: number,
    previousBoardData?: BoardData
): BoardData => {
    return create(words, rows, cols, previousBoardData, false);
};

// Creates the grid of characters for the given words
const create = (
    words: string[],
    preferredRows: number,
    preferredCols: number,
    previousBoardData?: BoardData,
    tripEmptyRowsAndCols = true
): BoardData => {
    for (let extra = 0; extra < 1000; extra++) {
        const rows = preferredRows + extra;
        const cols = preferredCols + extra;

        if (extra !== 0) {
            console.log(
                `[BoardCreator] Could not create valid board with rows: ${rows - 1} cols: ${cols - 1}... Trying again with rows: ${rows} cols: ${cols}`
            );
        }

        // Create the blank board
        const board = createBoard(cols, rows);

        // Sort words and try to place them on the board
        words = sortWords(words);
        if (placeWords(board, words, 0)) {
            const newBoardData = createBoardDataFromBoard(
                board,
                tripEmptyRowsAndCols
            );
            printBoardDataCells(newBoardData, 'Final board');

            // Compare with the last board data
            if (!isBoardDataSame(newBoardData, previousBoardData)) {
                return newBoardData;
            }
        }
    }

    console.error(
        '[BoardCreator] Failed creating a board with the given input'
    );
    return null;
};

// Creates the initial board or characters
function createBoard(xCells: number, yCells: number): Board {
    const board = new Board();

    board.xCells = xCells;
    board.yCells = yCells;
    board.placedVerticals = new Array<boolean>(xCells).fill(false);
    board.grid = Array.from({ length: xCells }, () => new Array<Cell>(yCells));
    board.undos = [];

    for (let x = 0; x < xCells; x++) {
        board.grid[x] = new Array<Cell>(yCells);

        for (let y = 0; y < yCells; y++) {
            const cell = new Cell();
            cell.x = x;
            cell.y = y;
            board.grid[x][y] = cell;
        }
    }

    // printCells(board, 'Initial board');
    return board;
}

function isBoardDataSame(
    boardData1?: BoardData,
    boardData2?: BoardData
): boolean {
    if (!boardData1 || !boardData2) {
        return false;
    }

    console.log('Comparing boards...');
    console.log('Board 1:', boardData1.rows, boardData1.cols);
    console.log('Board 2:', boardData2.rows, boardData2.cols);

    if (
        boardData1.rows !== boardData2.rows ||
        boardData1.cols !== boardData2.cols
    ) {
        return false;
    }

    let sameShape = true;
    let characters = 0;

    // Count the number of non-empty characters
    for (let i = 0; i < boardData1.rows; i++) {
        for (let j = 0; j < boardData1.cols; j++) {
            if (boardData1.board[i][j] !== '\x00') {
                characters++;
            }
        }
    }

    // Compare the shape of the boards
    for (let i = 0; i < boardData1.rows; i++) {
        for (let j = 0; j < boardData1.cols; j++) {
            const isEmpty1 = boardData1.board[i][j] === '\x00';
            const isEmpty2 = boardData2.board[i][j] === '\x00';

            if (isEmpty1 !== isEmpty2) {
                sameShape = false;
                break; // Exit inner loop early if shapes differ
            }
        }
        if (!sameShape) {
            break; // Exit outer loop early if shapes differ
        }
    }

    console.log('Same shape:', sameShape);
    console.log('Characters count:', characters);

    // If shapes differ, boards are not the same and there are not that many characters - return true
    if (sameShape && characters < 35) {
        return true;
    }

    for (let i = 0; i < boardData1.rows; i++) {
        for (let j = 0; j < boardData1.cols; j++) {
            if (boardData1.board[i][j] !== boardData2.board[i][j]) {
                return false;
            }
        }
    }

    return true;
}

// Creates a BoardData object for the given board char matrix
function createBoardDataFromBoard(
    board: Board,
    trimEmptyRowsAndCols
): BoardData {
    const boardData = new BoardData();

    boardData.rows = board.yCells;
    boardData.cols = board.xCells;
    boardData.board = [];

    // Convert the Board's grid to the BoardData's board
    for (let row = 0; row < board.yCells; row++) {
        boardData.board.push([]);

        for (let col = 0; col < board.xCells; col++) {
            boardData.board[row].push(board.grid[col][row].letter);
        }
    }

    if (trimEmptyRowsAndCols) {
        // Trim empty columns from the start
        while (
            boardData.cols > 0 &&
            boardData.board.every(row => row[0] === '\0')
        ) {
            for (let row = 0; row < boardData.rows; row++) {
                boardData.board[row].shift();
            }
            boardData.cols--;
        }

        // Trim empty columns from the end
        while (
            boardData.cols > 0 &&
            boardData.board.every(row => row[boardData.cols - 1] === '\0')
        ) {
            for (let row = 0; row < boardData.rows; row++) {
                boardData.board[row].pop();
            }
            boardData.cols--;
        }

        // Trim empty rows from the start
        while (
            boardData.rows > 0 &&
            boardData.board[0].every(cell => cell === '\0')
        ) {
            boardData.board.shift();
            boardData.rows--;
        }

        // Trim empty rows from the end
        while (
            boardData.rows > 0 &&
            boardData.board[boardData.rows - 1].every(cell => cell === '\0')
        ) {
            boardData.board.pop();
            boardData.rows--;
        }
    }

    return boardData;
}

function sortWords(words: string[]): string[] {
    // Sort the words by length in descending order
    const sortedWords = words.sort((a, b) => b.length - a.length);

    // Check if the longest word is longer by at least 2 characters than the next
    if (
        sortedWords.length > 1 &&
        sortedWords[0].length - sortedWords[1].length >= 2
    ) {
        // Keep the longest word as the first element
        const longestWord = sortedWords[0];

        // Shuffle the rest of the words
        const otherWords = sortedWords.slice(1);
        shuffle(otherWords);

        // Combine the longest word with the shuffled words
        return [longestWord, ...otherWords];
    } else {
        // If the longest word is not longer by at least 2 characters, shuffle all words
        shuffle(sortedWords);
        return sortedWords;
    }
}

// Places the next word on the board
function placeWords(
    board: Board,
    words: string[],
    nextWordIndex: number
): boolean {
    if (nextWordIndex >= words.length) {
        return true;
    }

    const wordToPlace = words[nextWordIndex];

    // Get all the possible starting cells for this word
    const [possiblePlacements, numHorizontal] = getPossibleStartingPlacements(
        board,
        wordToPlace.length,
        nextWordIndex === 0
    );

    // Keep trying random placements until we find one that leads to a completed board
    for (let i = 0; i < possiblePlacements.length; i++) {
        let randIndex: number;

        if (numHorizontal !== 0) {
            if (nextWordIndex % 2 === 0) {
                // Pick a vertical word
                randIndex = random.nextRange(0, numHorizontal);
            } else {
                // Pick a horizontal word
                randIndex = random.nextRange(
                    possiblePlacements.length - numHorizontal,
                    possiblePlacements.length
                );
            }
        } else {
            randIndex = random.nextRange(i, possiblePlacements.length);
        }

        const placement = possiblePlacements[randIndex];

        // Swap placements
        [possiblePlacements[randIndex], possiblePlacements[i]] = [
            possiblePlacements[i],
            placement
        ];

        board.undos.push(new Map<string, Undo>());

        // Place the word on the board
        placeWordAt(board, wordToPlace, placement);

        // Try and place the remaining words
        if (placeWords(board, words, nextWordIndex + 1)) {
            return true;
        }

        // If we get here, placing the word at startingCell makes it so one or more of the remaining words cannot be placed so remove the word and try again
        undoChanges(board);
    }

    return false;
}

// Remove the given word that has been placed on the board starting at the given starting cell
function undoChanges(board: Board): void {
    const curUndos = board.undos[board.undos.length - 1];

    curUndos.forEach(undo => {
        switch (undo.undoType) {
            case UndoType.Cell: {
                const cellUndo = undo as CellUndo;
                const cell = cellUndo.cell;
                cell.letter = cellUndo.letter;
                break;
            }
            case UndoType.PlacedVertical: {
                const pvUndo = undo as PVUndo;
                board.placedVerticals[pvUndo.index] = pvUndo.value;
                break;
            }
        }
    });

    board.undos.pop();
}

// Places the given word on the board starting at the given starting cell
function placeWordAt(
    board: Board,
    wordToPlace: string,
    placement: Placement
): void {
    // console.log(`PlaceWordAt: ${wordToPlace}, x:${placement.cell.x}, y:${placement.cell.y}, type:${placement.type}`);

    switch (placement.type) {
        case PlacementType.Vertical:
            pushLettersUp(
                board,
                placement.cell.x,
                placement.cell.y,
                wordToPlace.length
            );
            break;
        case PlacementType.ShiftRight:
            shiftColumnsRight(board, placement.cell.x);
            break;
        case PlacementType.ShiftLeft:
            shiftColumnsLeft(board, placement.cell.x);
            break;
    }

    // 50% chance the word is flipped
    const flipWord = random.nextDouble() < 0.5;

    for (let i = 0; i < wordToPlace.length; i++) {
        const cellX =
            placement.cell.x +
            (placement.type === PlacementType.Horizontal ? i : 0);
        const cellY =
            placement.cell.y +
            (placement.type === PlacementType.Horizontal ? 0 : i);

        const cell = getCell(board, cellX, cellY);

        if (placement.type === PlacementType.Horizontal) {
            pushLettersUp(board, cellX, cellY, 1);
        }

        const letterIndex = flipWord ? wordToPlace.length - i - 1 : i;

        changeCellLetter(board, cell, wordToPlace[letterIndex]);
    }

    if (placement.type !== PlacementType.Horizontal) {
        changePlacedVertical(board, placement.cell.x, true);
    }

    // printCells(board, 'After place');
}

// Changes the cells letter, logs an Undo action
function changeCellLetter(board: Board, cell: Cell, toLetter: string): void {
    setUndoForCell(board, cell);
    cell.letter = toLetter;
}

// Changes the placement value, logs an Undo action
function changePlacedVertical(
    board: Board,
    index: number,
    toValue: boolean
): void {
    setUndoForPV(board, index);
    board.placedVerticals[index] = toValue;
}

// Gets the current Undo for the given cell
function setUndoForCell(board: Board, cell: Cell): void {
    const key = `${cell.x}_${cell.y}`;
    const curUndos = board.undos[board.undos.length - 1];

    if (!curUndos.has(key)) {
        const undo = new CellUndo();
        undo.cell = cell;
        undo.letter = cell.letter;

        curUndos.set(key, undo);
    }
}

// Gets the current Undo for the given cell
function setUndoForPV(board: Board, index: number): void {
    const key = index.toString();
    const curUndos = board.undos[board.undos.length - 1];

    if (!curUndos.has(key)) {
        const undo = new PVUndo();
        undo.index = index;
        undo.value = board.placedVerticals[index];

        curUndos.set(key, undo);
    }
}

// Gets the cell at the give x/y coords, expands the board if the y is out of bounds
function getCell(board: Board, x: number, y: number): Cell {
    // Expand the grid if the y coordinate is outside the current grid bounds
    while (y >= board.yCells) {
        for (let i = 0; i < board.xCells; i++) {
            // Create a new cell at the end of each column
            const cell = new Cell();
            cell.x = i;
            cell.y = board.yCells;

            if (!board.grid[i]) {
                board.grid[i] = [];
            }

            board.grid[i].push(cell);
        }
        board.yCells++;
    }

    return board.grid[x][y];
}

// Pushes all letters up starting at the given x/y. Returns the top most blank cell after all letters have been pushed up
function pushLettersUp(
    board: Board,
    x: number,
    y: number,
    numSpaces: number
): void {
    if (getCell(board, x, y).letter === '\0') {
        return;
    }

    pushLettersUp(board, x, y + 1, numSpaces);

    changeCellLetter(
        board,
        getCell(board, x, y + numSpaces),
        getCell(board, x, y).letter
    );
}

function shiftColumnsLeft(board: Board, colX: number): void {
    for (let x = 1; x <= colX; x++) {
        for (let y = 0; y < board.yCells; y++) {
            const fromCell = getCell(board, x, y);
            const toCell = getCell(board, x - 1, y);

            changeCellLetter(board, toCell, fromCell.letter);

            if (x === colX) {
                changeCellLetter(board, fromCell, '\0');
            }
        }

        changePlacedVertical(board, x - 1, board.placedVerticals[x]);
    }
}

function shiftColumnsRight(board: Board, colX: number): void {
    for (let x = board.xCells - 2; x >= colX; x--) {
        for (let y = 0; y < board.yCells; y++) {
            const fromCell = getCell(board, x, y);
            const toCell = getCell(board, x + 1, y);

            changeCellLetter(board, toCell, fromCell.letter);

            if (x === colX) {
                changeCellLetter(board, fromCell, '\0');
            }
        }

        changePlacedVertical(board, x + 1, board.placedVerticals[x]);
    }
}

// Gets a list of possible starting cell placements for a word with the given length
function getPossibleStartingPlacements(
    board: Board,
    len: number,
    firstWord: boolean
): [Placement[], number] {
    let numHorizontal = 0;
    const possiblePlacements: Placement[] = [];

    const colBlanks = new Array<number>(board.xCells).fill(0);

    for (let y = board.yCells - 1; y >= 0; y--) {
        let hMaxLen = 0;
        let hMinLen = 1;

        const bottomRow = y === 0;
        let bottomBlankCells = 0;
        let bottomBlankCellsSeen = 0;

        if (bottomRow) {
            for (let x = 0; x < board.xCells; x++) {
                const curCell = board.grid[x][y];

                if (curCell.letter === '\0') {
                    bottomBlankCells++;
                }
            }

            // Assign hMinLen to -1 at the start of the bottom row, this will then be set to 1 only when it first encounters a NON blank cell
            // We will also only add horizontal words if hMinLen is NOT -1. Doing this will make words connected on the bottom row so no gaps are between them
            hMinLen = -1;
        }

        for (let x = board.xCells - 1; x >= 0; x--) {
            const curCell = board.grid[x][y];
            const belowCell = bottomRow ? null : board.grid[x][y - 1];

            // Update the number of blank cells in the column
            if (curCell.letter === '\0') {
                colBlanks[x]++;

                if (bottomRow) {
                    bottomBlankCellsSeen++;
                }
            } else if (bottomRow && len <= board.yCells) {
                if (
                    bottomBlankCellsSeen > 0 &&
                    x > 0 &&
                    board.grid[x - 1][0].letter !== '\0'
                ) {
                    possiblePlacements.unshift(
                        new Placement(curCell, PlacementType.ShiftRight)
                    );
                }

                if (
                    bottomBlankCells - bottomBlankCellsSeen > 0 &&
                    x < board.xCells - 1 &&
                    board.grid[x + 1][0].letter !== '\0'
                ) {
                    possiblePlacements.unshift(
                        new Placement(curCell, PlacementType.ShiftLeft)
                    );
                }
            }

            // Update the maximum length a word can be
            if (colBlanks[x] > 0 && (!belowCell || belowCell.letter !== '\0')) {
                hMaxLen++;
            } else {
                hMaxLen = 0;
            }

            // Update the minimum length a word can be
            if (firstWord || curCell.letter !== '\0') {
                hMinLen = 1;
            } else if (hMinLen !== -1) {
                hMinLen++;
            }

            // Check if possible horizontal start
            if (hMinLen !== -1 && len <= hMaxLen && len >= hMinLen) {
                possiblePlacements.push(
                    new Placement(curCell, PlacementType.Horizontal)
                );
                numHorizontal++;
            }

            // Check if possible vertical start
            if (
                !board.placedVerticals[x] &&
                colBlanks[x] >= len &&
                ((bottomRow && firstWord) || curCell.letter !== '\0')
            ) {
                possiblePlacements.unshift(
                    new Placement(curCell, PlacementType.Vertical)
                );
            }
        }
    }

    return [possiblePlacements, numHorizontal];
}

export { createBoardData, createInitialBoardData };

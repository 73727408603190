import { FC, useEffect, useState } from 'react';
import classNames from 'classnames';
import { motion, useAnimation } from 'framer-motion';

import { SoundName } from '@/audio/AudioManager';
import ModalInfo from '@/components/ModalInfo';
import { useLabels } from '@/context';
import emitter from '@/events/emitter';
import { PhraseInfoLocalized } from '@/types/category';

import {
    BACKGROUND_COLOR,
    BACKGROUND_COLOR_FOUND_PHRASE,
    questionStyles,
    TEXT_COLOR,
    TEXT_COLOR_FOUND
} from './questionStyles';

interface PhraseQuestionProps {
    phrase: PhraseInfoLocalized;
    answerFound: boolean;
}

function escapeRegExp(string: string): string {
    return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string
}

const PhraseQuestion: FC<PhraseQuestionProps> = ({ phrase, answerFound }) => {
    const { getLabel } = useLabels();
    const controls = useAnimation();

    const [isShowInstructions, setIsShowInstructions] =
        useState<boolean>(false);

    const getMaskedSentence = () => {
        if (answerFound) return phrase.sentence;

        const word = phrase.word;
        const escapedWord = escapeRegExp(word);

        const regex = new RegExp(`\\b${escapedWord}\\b`, 'gi');

        // Split the sentence based on the target word
        const parts = phrase.sentence.split(regex);

        // Find all matches (target words)
        const matches = phrase.sentence.match(regex) || [];

        // Combine parts and matches into an array of React elements
        const maskedSentence = parts.reduce((acc, part, index) => {
            acc.push(part);
            if (index < matches.length) {
                // Show the gray box
                acc.push(
                    <span
                        key={index}
                        className="mb-1 mr-1 inline-block h-5 w-16 rounded bg-gray-500 align-middle"
                        aria-label={`Missing word: ${phrase.word}`}
                    >
                        {/* The width can be adjusted based on the word length */}
                        {matches[index].length > 0 ? '\u00A0' : ''}
                    </span>
                );
            }
            return acc;
        }, [] as React.ReactNode[]);

        return maskedSentence;
    };

    useEffect(() => {
        controls.start({
            backgroundColor: answerFound
                ? BACKGROUND_COLOR_FOUND_PHRASE
                : BACKGROUND_COLOR,
            color: answerFound ? TEXT_COLOR_FOUND : TEXT_COLOR,
            transition: { duration: 0.5 }
        });
    }, [answerFound, controls]);

    const onQuestionPress = () => {
        emitter.emit('playSound', { sound: SoundName.Card });
        setIsShowInstructions(true);
    };

    return (
        <>
            <motion.button
                className={classNames(
                    questionStyles.container,
                    'relative my-1 flex flex-col items-center rounded-bl-none px-3 py-4 opacity-90'
                )}
                onClick={onQuestionPress}
                animate={controls}
                disabled={answerFound}
            >
                <motion.p
                    className={classNames(
                        questionStyles.fontBold,
                        'mb-1 text-lg'
                    )}
                    animate={{
                        color: answerFound ? phrase.categoryColor : TEXT_COLOR
                    }}
                >
                    {getMaskedSentence()}
                </motion.p>
                <motion.p
                    animate={{
                        color: answerFound ? TEXT_COLOR_FOUND : TEXT_COLOR
                    }}
                >
                    {phrase.translation}
                </motion.p>
            </motion.button>
            <ModalInfo
                isVisible={isShowInstructions}
                onClose={() => setIsShowInstructions(false)}
                description={getLabel(
                    'phrase-puzzle.swipe-missing-word-instruction'
                )}
            />
        </>
    );
};

export default PhraseQuestion;

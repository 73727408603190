import classNames from 'classnames';

import { HeaderWithCloseAndBackButton } from '@/components';
import { useGameProgress, useLabels, useNavigation } from '@/context';
import useDisableVerticalSwipe from '@/hooks/useDisableVerticalSwipe';
import { PhraseInfoLocalized } from '@/types/category';

import PhraseItem from './components/PhraseItem';

const PhrasesLearnedScreen = () => {
    useDisableVerticalSwipe();
    const { getLabel } = useLabels();
    const { navigateToProfile, closeProfile } = useNavigation();

    const { gameProgress } = useGameProgress();

    const categoriesWithProgress = gameProgress.categoriesProgress.filter(
        category => category.categoryProgressItem.phraseProgressItems.length > 0
    );

    const categoriesWithProgressContent = categoriesWithProgress
        .map(cat => cat.categoryInfo.phrases)
        .flat();

    const learnedPhrasesIds = gameProgress.categoriesProgress
        .map(progress => progress.categoryProgressItem.phraseProgressItems)
        .flat()
        .map(phrase => phrase.phraseId);

    const learnedPhrases = learnedPhrasesIds.map(id =>
        categoriesWithProgressContent.find(phrase => phrase.id === id)
    );

    const learnedPhrasesByCategory = learnedPhrases.reduce(
        (acc, currentPhrase) => {
            if (!currentPhrase) return acc;
            const { categoryId } = currentPhrase;
            if (!acc[categoryId]) {
                acc[categoryId] = [];
            }
            acc[categoryId].push(currentPhrase);
            return acc;
        },
        {} as Record<string, PhraseInfoLocalized[]>
    );

    const learnedPhrasesByCategoryArray = Object.keys(
        learnedPhrasesByCategory
    ).map(categoryId => ({
        categoryId,
        phrases: learnedPhrasesByCategory[categoryId]
    }));

    const getCategoryName = (id: string) => {
        const category = gameProgress.categoriesProgress.find(
            category => category.categoryInfo.id === id
        );
        return category ? category.categoryInfo.name : 'Unknown Category';
    };

    const isLastCategory = (index: number) => {
        return index !== learnedPhrasesByCategoryArray.length - 1;
    };

    return (
        <div className="flex h-full flex-col">
            <HeaderWithCloseAndBackButton
                onClose={() => closeProfile()}
                onBack={() => navigateToProfile('Account')}
                backTitle={getLabel('common.back')}
            />
            <h2 className="py-2">{getLabel('profile.phrases-learned')}</h2>
            <div className="flex-grow overflow-y-scroll">
                <div className="py-5">
                    {learnedPhrasesByCategoryArray.map((category, index) => (
                        <div
                            key={category.categoryId}
                            className={classNames(
                                'py-5',
                                isLastCategory(index) &&
                                    'border-b border-white/20'
                            )}
                        >
                            <h3 className="uppercase">
                                {getCategoryName(category.categoryId)}
                            </h3>
                            {category.phrases.map(phrase => (
                                <PhraseItem
                                    item={phrase}
                                    learnt
                                    key={phrase.id}
                                    hideDot
                                />
                            ))}
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default PhrasesLearnedScreen;

import {
    CategoryInfoWithProgress,
    PhraseInfoLocalized,
    WordInfoLocalized
} from '../types/category';
import { PhraseProgressItem, WordProgressItem } from '../types/progress';

import { shuffle } from './array';

const intervalsInMilliseconds = [
    0, // 0 seconds
    3600000, // 1 hour = 3600 seconds * 1000 milliseconds
    14400000, // 4 hours = 4 * 3600 seconds * 1000 milliseconds
    28800000, // 8 hours = 8 * 3600 seconds * 1000 milliseconds
    86400000, // 1 day = 24 * 3600 seconds * 1000 milliseconds
    259200000, // 3 days = 3 * 24 * 3600 seconds * 1000 milliseconds
    604800000, // 7 days = 7 * 24 * 3600 seconds * 1000 milliseconds
    1209600000, // 14 days = 14 * 24 * 3600 seconds * 1000 milliseconds
    2419200000, // 28 days = 28 * 24 * 3600 seconds * 1000 milliseconds
    4838400000 // 56 days = 56 * 24 * 3600 seconds * 1000 milliseconds
];

function shouldReview(item: WordProgressItem | PhraseProgressItem): boolean {
    if (!item.box) return true;

    const now = new Date();

    // Calculate the next due date for review by adding the interval
    // TODO: For some reason movedToBoxAt is sometimes a string, so we need to convert it to a Date object. Fix later if needed
    const nextReviewDate = new Date(
        new Date(item.movedToBoxAt).getTime() +
            intervalsInMilliseconds[item.box]
    );

    return now >= nextReviewDate;
}

function getAllWordsToReviewCount(
    categoriesWithProgress: CategoryInfoWithProgress[]
): number {
    let wordsToReviewCount = 0;

    // Iterate over each category to check each word's review status
    for (const category of categoriesWithProgress) {
        for (const wordProgressItem of category.categoryProgressItem
            .wordProgressItems) {
            // Check if the word is due for review using the shouldRepeat function
            if (shouldReview(wordProgressItem)) {
                wordsToReviewCount++;
            }
        }
    }

    return wordsToReviewCount;
}

function getAllPhrasesToReviewCount(
    categoriesWithProgress: CategoryInfoWithProgress[]
): number {
    let phrasesToReviewCount = 0;

    // Iterate over each category to check each phrase's review status
    for (const category of categoriesWithProgress) {
        for (const phraseProgressItem of category.categoryProgressItem
            .phraseProgressItems) {
            // Check if the phrase is due for review using the shouldRepeat function
            if (shouldReview(phraseProgressItem)) {
                phrasesToReviewCount++;
            }
        }
    }

    return phrasesToReviewCount;
}

function getAllWordsToReview(
    categoriesWithProgress: CategoryInfoWithProgress[]
): WordInfoLocalized[] {
    const reviewableWords: WordInfoLocalized[] = [];

    // Gather all words that need to be reviewed
    categoriesWithProgress.forEach(category => {
        category.categoryProgressItem.wordProgressItems.forEach(
            wordProgressItem => {
                if (shouldReview(wordProgressItem)) {
                    const wordInfo = category.categoryInfo.words.find(
                        word => word.id === wordProgressItem.wordId
                    );
                    if (wordInfo) {
                        reviewableWords.push(wordInfo);
                    }
                }
            }
        );
    });

    // Shuffle the array to randomize the order of review
    shuffle(reviewableWords);
    return reviewableWords;
}

function getAllPhrasesToReview(
    categoriesWithProgress: CategoryInfoWithProgress[]
): PhraseInfoLocalized[] {
    const reviewablePhrases: PhraseInfoLocalized[] = [];

    // Gather all phrases that need to be reviewed
    categoriesWithProgress.forEach(category => {
        category.categoryProgressItem.phraseProgressItems.forEach(
            phraseProgressItem => {
                if (shouldReview(phraseProgressItem)) {
                    const phraseInfo = category.categoryInfo.phrases.find(
                        phrase => phrase.id === phraseProgressItem.phraseId
                    );
                    if (phraseInfo) {
                        reviewablePhrases.push(phraseInfo);
                    }
                }
            }
        );
    });

    // Shuffle the array to randomize the order of review
    shuffle(reviewablePhrases);
    return reviewablePhrases;
}

function getAllWordsInProgress(
    categoriesWithProgress: CategoryInfoWithProgress[]
): WordInfoLocalized[] {
    const wordsInProgress: WordInfoLocalized[] = [];

    // Iterate over each category to gather all words in progress
    categoriesWithProgress.forEach(category => {
        category.categoryProgressItem.wordProgressItems.forEach(
            wordProgressItem => {
                const wordInfo = category.categoryInfo.words.find(
                    word => word.id === wordProgressItem.wordId
                );
                if (wordInfo) {
                    wordsInProgress.push(wordInfo);
                }
            }
        );
    });

    console.log('wordsInProgress', wordsInProgress.length);

    // Shuffle the array to randomize the order
    shuffle(wordsInProgress);
    return wordsInProgress;
}

function getAllPhrasesInProgress(
    categoriesWithProgress: CategoryInfoWithProgress[]
): PhraseInfoLocalized[] {
    const phrasesInProgress: PhraseInfoLocalized[] = [];

    // Iterate over each category to gather all phrases in progress
    categoriesWithProgress.forEach(category => {
        category.categoryProgressItem.phraseProgressItems.forEach(
            phraseProgressItem => {
                const phraseInfo = category.categoryInfo.phrases.find(
                    phrase => phrase.id === phraseProgressItem.phraseId
                );
                if (phraseInfo) {
                    phrasesInProgress.push(phraseInfo);
                }
            }
        );
    });

    // Shuffle the array to randomize the order
    shuffle(phrasesInProgress);
    return phrasesInProgress;
}

export {
    getAllPhrasesInProgress,
    getAllPhrasesToReview,
    getAllPhrasesToReviewCount,
    getAllWordsInProgress,
    getAllWordsToReview,
    getAllWordsToReviewCount
};

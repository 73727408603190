import { useEffect } from 'react';
import { swipeBehavior } from '@telegram-apps/sdk-react';

const useDisableVerticalSwipe = () => {
    useEffect(() => {
        swipeBehavior.disableVertical();

        return () => {
            swipeBehavior.enableVertical();
        };
    }, [swipeBehavior]);
};

export default useDisableVerticalSwipe;

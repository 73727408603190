import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { useAsyncFn } from 'react-use';
import { motion } from 'framer-motion';
import { CountUp } from 'use-count-up';

import { SoundName } from '@/audio/AudioManager';
import { Panel, TextButton } from '@/components';
import {
    useGameConfig,
    useNewPhrasesGameSession,
    useSettings
} from '@/context';
import { useGameProgress } from '@/context/GameProgressContext';
import { useLabels } from '@/context/LabelsContext';
import { useNavigation } from '@/context/NavigationContext';
import emitter from '@/events/emitter';
import { resultScreenStyles } from '@/styles/resultScreenStyles';

import coinIcon from '../../../assets/images/icons/coin.png';
import xpIcon from '../../../assets/images/icons/xp.png';

const PhrasePuzzleResultsScreen: React.FC = () => {
    const { getLabel } = useLabels();
    const { navigate } = useNavigation();
    const { settings } = useSettings();
    const { gameConfig } = useGameConfig();
    const {
        updateStreak,
        addCoins,
        addXp,
        getPhrasesLearnedCount,
        updateCategoriesProgress
    } = useGameProgress();
    const { gameSession } = useNewPhrasesGameSession();

    const [coins, setCoins] = useState(0);
    const [xp, setXp] = useState(0);
    const [animate, setAnimate] = useState(false);

    const phrasesLearnedBefore = getPhrasesLearnedCount();
    const [phrasesLearnedCounter, setPhrasesLearnedCounter] =
        useState(phrasesLearnedBefore);

    // **Handle Animations and Sounds Only Once on Mount**
    useEffect(() => {
        const timeoutCoins = setTimeout(() => {
            emitter.emit('playSound', { sound: SoundName.Coin });
            const earnedCoins = gameSession.earnedCoins;
            setCoins(earnedCoins);
            setXp(earnedCoins * gameConfig.xpPerCoinReward);
            setAnimate(true);
        }, 300);

        const timeoutLearnedPhrases = setTimeout(() => {
            setPhrasesLearnedCounter(
                phrasesLearnedBefore + gameSession.learnedPhrases.length
            );
        }, 1500);

        return () => {
            clearTimeout(timeoutCoins);
            clearTimeout(timeoutLearnedPhrases);
        };
    }, []); // **Empty dependency array ensures this runs only once on mount**

    // **Reset Animation State After Animation Completes**
    useEffect(() => {
        if (animate) {
            // Reset animation after coins and XP animations
            const resetAnimation = setTimeout(() => {
                setAnimate(false);
            }, 600);

            return () => clearTimeout(resetAnimation);
        }
    }, [animate]);

    const [handleContinueState, doHandleContinue] = useAsyncFn(async () => {
        await handleProgressUpdateAndNavigate();
    }, [settings.studyLanguage, gameSession.learnedPhrases, navigate]);

    // **Extracted Function to Handle Progress Update and Navigation**
    const handleProgressUpdateAndNavigate = async () => {
        try {
            const earnedCoins = gameSession.earnedCoins;
            const earnedXp = earnedCoins * gameConfig.xpPerCoinReward;

            const [streak] = await Promise.all([
                updateStreak(),
                addCoins(earnedCoins),
                addXp(settings.studyLanguage, earnedXp),
                updateCategoriesProgress(
                    settings.studyLanguage,
                    [],
                    gameSession.learnedPhrases,
                    gameSession.category
                )
            ]);
            navigate(streak.rewardClaimed ? 'ChooseCategory' : 'Streak');
        } catch (error) {
            console.error(error);
            toast.error(getLabel('error.failed-to-update-progress'));
            throw error; // **Re-throw to allow useAsyncFn to capture the error**
        }
    };

    const onContinuePress = async () => {
        await doHandleContinue();
    };

    return (
        <div className="flex h-full flex-col justify-center">
            <Panel
                className={`${resultScreenStyles.panel} flex flex-col items-center`}
            >
                <h1>{getLabel('common.great-job')}</h1>
                <div className="my-4 flex w-full justify-around px-4">
                    <div className={`${resultScreenStyles.coinsWrapper}`}>
                        <motion.img
                            src={xpIcon}
                            className={`${resultScreenStyles.icon} h-16 w-16`}
                            animate={animate ? { scale: [1, 1.05, 1] } : {}}
                            transition={animate ? { duration: 0.6 } : {}}
                            alt="XP Icon"
                        />
                        <span
                            className={`${resultScreenStyles.counter} text-white200`}
                        >
                            +
                            <CountUp
                                key={xp}
                                isCounting
                                end={xp}
                                duration={1}
                            />
                        </span>
                    </div>
                    <div className={`${resultScreenStyles.coinsWrapper}`}>
                        <motion.img
                            src={coinIcon}
                            className={`${resultScreenStyles.icon} h-16 w-16`}
                            animate={animate ? { scale: [1, 1.05, 1] } : {}}
                            transition={animate ? { duration: 0.6 } : {}}
                            alt="Coin Icon"
                        />
                        <span
                            className={`${resultScreenStyles.counter} text-white200`}
                        >
                            +
                            <CountUp
                                key={coins}
                                isCounting
                                end={coins}
                                duration={1}
                            />
                        </span>
                    </div>
                </div>
                <div
                    className={`${resultScreenStyles.learnedItemsView} mt-6 text-center`}
                >
                    <h3>
                        {getLabel('results-screen.learned-phrases')}
                        {': '}
                        <CountUp
                            key={phrasesLearnedCounter}
                            isCounting
                            start={phrasesLearnedBefore}
                            end={phrasesLearnedCounter}
                            duration={0.5}
                        />
                    </h3>
                </div>
            </Panel>
            <TextButton
                onClick={onContinuePress}
                text={getLabel('button.continue')}
                isDisabled={handleContinueState.loading}
                isLoading={handleContinueState.loading}
            />
        </div>
    );
};

export default PhrasePuzzleResultsScreen;

import { memo, useEffect, useMemo, useState } from 'react';
import { motion } from 'framer-motion';

import { SoundName } from '@/audio/AudioManager';
import { ProgressBar } from '@/components';
import {
    useGameProgress,
    useNavigation,
    useReviewGameSession,
    useSettings
} from '@/context';
import emitter, { WrongTranslationSelectedPayload } from '@/events/emitter';
import useDisableVerticalSwipe from '@/hooks/useDisableVerticalSwipe';
import useEnableClosingConfirmation from '@/hooks/useEnableClosingConfirmation';
import { PhraseInfoLocalized, WordInfoLocalized } from '@/types/category';
import { COLORS } from '@/utils/colors';

import GameStep from './GameStep';
import HintsPanel from './HintsPanel';
import PhraseGameStep from './PhraseGameStep';
import PhraseHintsPanel from './PhraseHintsPanel';
import { PuzzleType } from './types';

const ReviewPuzzleScreen = memo(() => {
    useDisableVerticalSwipe();
    useEnableClosingConfirmation();

    const { navigate } = useNavigation();
    const {
        reviewGameSession,
        updateReviewGameSessionSteps,
        addFoundWord,
        addCorrectWord,
        addFoundPhrase,
        addCorrectPhrase
    } = useReviewGameSession();
    const { settings } = useSettings();

    const [wrongWordIds, setWrongWordIds] = useState<string[]>([]);
    const [stepCompleted, setStepCompleted] = useState(false);
    const [updateInitiated, setUpdateInitiated] = useState(false);

    const currentStepIndex = reviewGameSession.currentStep - 1;

    const currentStep = useMemo(() => {
        return reviewGameSession.steps[currentStepIndex];
    }, [reviewGameSession.steps, currentStepIndex]);

    useEffect(() => {
        const handleCorrectAnswerGiven = (payload: {
            word: WordInfoLocalized;
        }) => {
            addFoundWord(payload.word);
        };

        const handleCorrectTranslationSelected = (payload: {
            word: WordInfoLocalized;
        }) => {
            if (!wrongWordIds.includes(payload.word.id)) {
                addCorrectWord(payload.word);
            }
        };

        const handleInvalidWordSelected = () => {
            emitter.emit('playSound', { sound: SoundName.Wrong });
        };

        const handleWrongTranslationSelected = ({
            word
        }: WrongTranslationSelectedPayload) => {
            setWrongWordIds(prevWordIds => [...prevWordIds, word.id]);
        };

        const handleCorrectPhraseAnswerGiven = (payload: {
            phrase: PhraseInfoLocalized;
        }) => {
            // TODO: don't save phrase to correct phrases if there was a mistake first (see setWrongWordIds implementation)
            addFoundPhrase(payload.phrase);
            addCorrectPhrase(payload.phrase);
        };

        emitter.on('correctAnswerGiven', handleCorrectAnswerGiven);
        emitter.on('invalidWordSelected', handleInvalidWordSelected);
        emitter.on(
            'correctTranslationSelected',
            handleCorrectTranslationSelected
        );
        emitter.on('wrongTranslationSelected', handleWrongTranslationSelected);

        emitter.on('correctPhraseAnswerGiven', handleCorrectPhraseAnswerGiven);

        return () => {
            emitter.off('correctAnswerGiven', handleCorrectAnswerGiven);
            emitter.off('invalidWordSelected', handleInvalidWordSelected);
            emitter.off(
                'correctTranslationSelected',
                handleCorrectTranslationSelected
            );
            emitter.off(
                'wrongTranslationSelected',
                handleWrongTranslationSelected
            );
            emitter.off(
                'correctPhraseAnswerGiven',
                handleCorrectPhraseAnswerGiven
            );
        };
    }, [
        addFoundWord,
        addCorrectWord,
        wrongWordIds,
        addFoundPhrase,
        addCorrectPhrase
    ]);

    const completeAndNavigate = () => {
        setTimeout(() => {
            navigate('ReviewResults');
        }, 500);
    };

    useEffect(() => {
        if (
            reviewGameSession.currentStep > reviewGameSession.totalSteps &&
            !updateInitiated
        ) {
            setUpdateInitiated(true);
            completeAndNavigate();
        }
    }, [
        reviewGameSession.currentStep,
        reviewGameSession.totalSteps,
        updateInitiated
    ]);

    const onAllItemsFound = () => {
        const nextStep = reviewGameSession.currentStep + 1;
        updateReviewGameSessionSteps(nextStep, reviewGameSession.totalSteps);
        setStepCompleted(false);
    };

    const onLeavePuzzle = async () => {
        if (
            reviewGameSession.correctWords.length === 0 &&
            reviewGameSession.foundWords.length === 0 &&
            reviewGameSession.correctPhrases.length === 0 &&
            reviewGameSession.foundPhrases.length === 0
        ) {
            navigate('ChooseCategory');
            return;
        }
        navigate('ReviewResults');
    };

    useEffect(() => {
        if (currentStep?.type === 'words') {
            const allWordsFound = currentStep.words.every(word =>
                reviewGameSession.foundWords.some(fw => fw.id === word.id)
            );
            if (allWordsFound && !stepCompleted) {
                setStepCompleted(true);
                // timeout for smooth animation
                setTimeout(() => {
                    onAllItemsFound();
                }, 750);
            }
        }
    }, [reviewGameSession.foundWords, currentStep, stepCompleted]);

    useEffect(() => {
        setStepCompleted(false);
    }, [reviewGameSession.currentStep]);

    const generateContent = () => {
        if (
            !currentStep ||
            reviewGameSession.currentStep > reviewGameSession.totalSteps
        ) {
            return null;
        }

        return (
            <>
                {currentStep.type === 'words' ? (
                    <GameStep
                        key={`words-step-${currentStep.words.map(word => word.id).join('-')}`}
                        puzzleData={{
                            puzzleType: PuzzleType.ChooseTranslation,
                            wordsToPlay: currentStep.words,
                            selectedTileColor: COLORS.blue
                        }}
                        studyLanguage={settings.studyLanguage}
                        showCursor={false}
                        onAllWordsFound={() => {}}
                    />
                ) : (
                    <PhraseGameStep
                        key={`phrase-${currentStep.phrase.id}`}
                        phrase={currentStep.phrase}
                        onAnswerFound={onAllItemsFound}
                    />
                )}
                <motion.div
                    className="mb-2 mt-5 px-3"
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1, transition: { duration: 0.5 } }}
                >
                    {currentStep.type === 'words' ? (
                        <HintsPanel
                            puzzleType={PuzzleType.ChooseTranslation}
                            onLeavePuzzle={onLeavePuzzle}
                            isReview
                        />
                    ) : (
                        <PhraseHintsPanel
                            onLeavePuzzle={onLeavePuzzle}
                            isReview
                        />
                    )}
                </motion.div>
            </>
        );
    };

    return (
        <div className="flex h-full flex-col">
            {/* Steps Progress Bar */}
            <div className="mb-1 h-2">
                <ProgressBar
                    progressBarValue={
                        (reviewGameSession.currentStep - 1) /
                        reviewGameSession.totalSteps
                    }
                    bgColor="orange"
                    borderColor="border-none"
                    unfilledColor="bg-grey700/30"
                    height="h-1.5"
                />
            </div>
            {generateContent()}
        </div>
    );
});

ReviewPuzzleScreen.displayName = 'ReviewPuzzleScreen';

const ReviewPuzzleScreenWrapper: React.FC = () => {
    const { getWordsToReview, getPhrasesToReview } = useGameProgress();
    const { initializeReviewSteps, reviewGameSession } = useReviewGameSession();
    const { settings } = useSettings();

    useEffect(() => {
        const wordsToReview = getWordsToReview(settings?.studyLanguage);
        const phrasesToReview = getPhrasesToReview();
        initializeReviewSteps(wordsToReview, phrasesToReview);
    }, []);
    // Avoid reinitialization by empty dependency array. getWordsToReview, getPhrasesToReview are triggering reinitialization
    // when user clicks on the magic wand hint in the puzzle. Weird issue related to state update that can be investigated later if needed.

    // Wait until reviewGameSession.steps are initialized
    if (reviewGameSession.totalSteps === 0) {
        return null;
    }

    return <ReviewPuzzleScreen />;
};

export default ReviewPuzzleScreenWrapper;

import { type FC, useEffect, useState } from 'react';
import ReactGA from 'react-ga4';

import { BackgroundImageProvider } from '@/context/BackgroundImageContext';
import { ContentProvider } from '@/context/ContentContext';
import { GameProgressProvider } from '@/context/GameProgressContext';
import { LabelsProvider } from '@/context/LabelsContext';
import { NavigationProvider } from '@/context/NavigationContext';
import { NewWordsSessionProvider } from '@/context/NewWordsGameSessionContext';
import { ProfileProvider } from '@/context/ProfileContext';
import AppNavigator from '@/navigation/AppNavigator';

import { audioManager } from './audio/AudioManager';
import { GameConfigProvider } from './context/GameConfigContext';
import { NewPhrasesSessionProvider } from './context/NewPhrasesGameSessionContext';
import { ReviewGameSessionProvider } from './context/ReviewGameSession';
import { SettingsProvider } from './context/SettingsContext';

export const App: FC = () => {
    const [updateChecked, setUpdateChecked] = useState(false);

    // Initialize ReactGA only in production
    useEffect(() => {
        if (process.env.NODE_ENV === 'production') {
            // TODO: move to env variables
            ReactGA.initialize('G-MJ01FV12GR');
        }
    }, []);

    useEffect(() => {
        audioManager.init();

        return () => {
            audioManager.cleanup();
        };
    }, []);

    useEffect(() => {
        const checkForUpdates = async () => {
            const hasNewScripts = await detectNewScripts();

            if (hasNewScripts) {
                console.log('FOUND NEW SCRIPTS, RELOADING');
                window.location.reload();
            } else {
                console.log('NO NEW SCRIPTS FOUND');
                setUpdateChecked(true);
            }
        };

        checkForUpdates();
    }, []);

    const detectNewScripts = async () => {
        const latestIndexHtml = await fetchLatestIndexHtml();
        console.log('LATEST INDEX HTML', latestIndexHtml);
        if (!latestIndexHtml) {
            // If we can't fetch the latest index.html, assume no update is available
            return false;
        }

        const parser = new DOMParser();
        const doc = parser.parseFromString(latestIndexHtml, 'text/html');

        // Get script sources from the latest index.html
        const newScripts = Array.from(doc.getElementsByTagName('script'))
            .map(script => script.src)
            .filter(src => src);

        // Get script sources from the currently loaded page
        const oldScripts = Array.from(document.getElementsByTagName('script'))
            .map(script => script.src)
            .filter(src => src);

        // Compare the scripts
        const hasNewScripts = !newScripts.every(src =>
            oldScripts.includes(src)
        );
        console.log('NEW SCRIPTS', newScripts);
        console.log('OLD SCRIPTS', oldScripts);
        console.log('HAS NEW SCRIPTS', hasNewScripts);

        return hasNewScripts;
    };

    const fetchLatestIndexHtml = async () => {
        const url = `${window.location.origin}/index.html?_=${new Date().getTime()}`;

        try {
            const response = await fetch(url, {
                method: 'GET',
                headers: {
                    'Cache-Control': 'no-cache',
                    Pragma: 'no-cache'
                }
            });
            return await response.text();
        } catch (error) {
            console.error('Failed to fetch index.html:', error);
            return null;
        }
    };

    if (!updateChecked) {
        return null;
    }

    return (
        <GameConfigProvider>
            <SettingsProvider>
                <NavigationProvider>
                    <ProfileProvider>
                        <LabelsProvider>
                            <ContentProvider>
                                <GameProgressProvider>
                                    <NewWordsSessionProvider>
                                        <NewPhrasesSessionProvider>
                                            <ReviewGameSessionProvider>
                                                <BackgroundImageProvider>
                                                    <AppNavigator />
                                                </BackgroundImageProvider>
                                            </ReviewGameSessionProvider>
                                        </NewPhrasesSessionProvider>
                                    </NewWordsSessionProvider>
                                </GameProgressProvider>
                            </ContentProvider>
                        </LabelsProvider>
                    </ProfileProvider>
                </NavigationProvider>
            </SettingsProvider>
        </GameConfigProvider>
    );
};

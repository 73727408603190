import {
    $debug,
    closingBehavior,
    cloudStorage,
    init as initSDK,
    initData,
    miniApp,
    swipeBehavior,
    viewport
} from '@telegram-apps/sdk-react';

/**
 * Initializes the application and configures its dependencies.
 */
export function init(debug: boolean): void {
    // Set @telegram-apps/sdk-react debug mode.
    $debug.set(debug);

    // Initialize special event handlers for Telegram Desktop, Android, iOS, etc.
    // Also, configure the package.
    initSDK();

    // Add Eruda if needed.
    debug &&
        import('eruda').then(lib => lib.default.init()).catch(console.error);

    // Check if all required components are supported.
    if (
        !miniApp.isSupported() ||
        !swipeBehavior.isSupported() ||
        !cloudStorage.isSupported()
    ) {
        throw new Error('ERR_NOT_SUPPORTED');
    }

    // Mount all components used in the project.
    miniApp.mount();
    swipeBehavior.mount();
    initData.restore();
    closingBehavior.mount();
    void viewport
        .mount()
        .catch(e => {
            console.error('Something went wrong mounting the viewport', e);
        })
        .then(() => {
            viewport.expand();
        });
}

import { useEffect, useMemo, useState } from 'react';
import { toast } from 'react-toastify';
import { useAsyncFn } from 'react-use';
import { motion } from 'framer-motion';
import { CountUp } from 'use-count-up';

import { SoundName } from '@/audio/AudioManager';
import { Panel, ProgressBar, TextButton } from '@/components';
import { useGameConfig, useNewWordsGameSession, useSettings } from '@/context';
import { useGameProgress } from '@/context/GameProgressContext';
import { useLabels } from '@/context/LabelsContext';
import { ScreenName, useNavigation } from '@/context/NavigationContext';
import emitter from '@/events/emitter';
import { resultScreenStyles } from '@/styles/resultScreenStyles';
import { COLORS } from '@/utils/colors';

import coinIcon from '../../../assets/images/icons/coin.png';
import landscapeIcon from '../../../assets/images/icons/landscape.png';
import xpIcon from '../../../assets/images/icons/xp.png';

const PROGRESS_BAR_MAX_VALUE = 10;

const ResultsScreen: React.FC = () => {
    const { getLabel } = useLabels();
    const { navigate } = useNavigation();
    const { settings } = useSettings();
    const { gameConfig } = useGameConfig();
    const {
        gameProgress,
        updateStreak,
        addCoins,
        addXp,
        getWordsLearnedCount,
        isLockedLandscapeAvailable,
        updateCategoriesProgress,
        goToNextGameLevel
    } = useGameProgress();
    const { gameSession } = useNewWordsGameSession();

    const [coins, setCoins] = useState(0);
    const [xp, setXp] = useState(0);
    const [animate, setAnimate] = useState(false);
    const [isNavigating, setIsNavigating] = useState(false);

    const wordsLearnedBefore = getWordsLearnedCount();
    const [wordsLearnedCounter, setWordsLearnedCounter] =
        useState(wordsLearnedBefore);
    const [landscapeProgress, setLandscapeProgress] = useState(
        gameProgress.landscapeProgress
    );

    // **Handle Animations and Sounds Only Once on Mount**
    useEffect(() => {
        const timeoutCoins = setTimeout(() => {
            emitter.emit('playSound', { sound: SoundName.Coin });
            const earnedCoins = gameSession.earnedCoins;
            setCoins(earnedCoins);
            setXp(earnedCoins * gameConfig.xpPerCoinReward);
            setAnimate(true);
        }, 300);

        let timeoutLandscapeProgress;
        if (isShowLandscapeProgress) {
            timeoutLandscapeProgress = setTimeout(() => {
                setLandscapeProgress(gameProgress.landscapeProgress + 1);
            }, 1500);
        }

        const timeoutLearnedWords = setTimeout(() => {
            setWordsLearnedCounter(
                getWordsLearnedCount() + gameSession.learnedWords.length
            );
        }, 1500);

        return () => {
            clearTimeout(timeoutCoins);
            clearTimeout(timeoutLandscapeProgress);
            clearTimeout(timeoutLearnedWords);
        };
    }, []); // **Empty dependency array ensures this runs only once on mount**

    // **Reset Animation State After Animation Completes**
    useEffect(() => {
        if (animate) {
            // Reset animation after coins and XP animations
            const resetAnimation = setTimeout(() => {
                setAnimate(false);
            }, 600);

            return () => clearTimeout(resetAnimation);
        }
    }, [animate]);

    // **Extracted Function to Handle Progress Update and Navigation**
    const handleProgressUpdateAndNavigate = async (navigateTo: ScreenName) => {
        try {
            const earnedCoins = gameSession.earnedCoins;

            const [streak] = await Promise.all([
                updateStreak(),
                addCoins(earnedCoins),
                addXp(
                    settings.studyLanguage,
                    earnedCoins * gameConfig.xpPerCoinReward
                ),
                // gameSession reviewedWords are simply ignored in NewWordsGameSession. Their status is updated in the review session
                updateCategoriesProgress(
                    settings.studyLanguage,
                    gameSession.learnedWords,
                    [],
                    gameSession.category
                ),
                goToNextGameLevel(settings.studyLanguage)
            ]);
            setIsNavigating(true);
            const navigateToFinal =
                navigateTo === 'ChooseCategory' && !streak.rewardClaimed
                    ? 'Streak'
                    : navigateTo;
            navigate(navigateToFinal);
        } catch (error) {
            console.error(error);
            toast.error(getLabel('error.failed-to-update-progress'));
            throw error; // **Re-throw to allow useAsyncFn to capture the error**
        }
    };

    // **Handle 'Continue' Button Click**
    const [handleContinueState, doHandleContinue] = useAsyncFn(async () => {
        await handleProgressUpdateAndNavigate('ChooseCategory');
    }, [
        updateCategoriesProgress,
        goToNextGameLevel,
        settings.studyLanguage,
        gameSession.learnedWords,
        navigate
    ]);

    // **Handle 'Unlock Landscape' Button Click**
    const [handleUnlockState, doHandleUnlock] = useAsyncFn(async () => {
        await handleProgressUpdateAndNavigate('OpenLandscape');
    }, [
        updateCategoriesProgress,
        goToNextGameLevel,
        settings.studyLanguage,
        gameSession.learnedWords,
        navigate
    ]);

    // **Define Handler for Button Presses**
    const handleButtonPress = async (action: 'continue' | 'unlock') => {
        if (action === 'continue') {
            await doHandleContinue();
        } else if (action === 'unlock') {
            await doHandleUnlock();
        }
    };

    const onContinuePress = () => {
        handleButtonPress('continue');
    };

    const onUnlockPress = () => {
        handleButtonPress('unlock');
    };

    // **Determine Button Availability and Progress**
    const isUnlockAvailable = useMemo(() => {
        return (
            isLockedLandscapeAvailable() &&
            (gameProgress.landscapeProgress + 1 === PROGRESS_BAR_MAX_VALUE ||
                gameProgress.gameLevel === 1)
        );
    }, []); // **Empty dependency array ensures this runs only once on mount**

    const isShowLandscapeProgress = useMemo(() => {
        return isLockedLandscapeAvailable() && gameProgress.gameLevel >= 2;
    }, []);

    const landscapesProgress = landscapeProgress / PROGRESS_BAR_MAX_VALUE;

    const isLoading =
        handleContinueState.loading ||
        handleUnlockState.loading ||
        isNavigating;

    return (
        <div className="flex h-full flex-col justify-center">
            <Panel
                className={`${resultScreenStyles.panel} flex flex-col items-center`}
            >
                <h1>{getLabel('common.great-job')}</h1>
                <div className="my-4 flex w-full justify-around px-4">
                    <div className={`${resultScreenStyles.coinsWrapper}`}>
                        <motion.img
                            src={xpIcon}
                            className={`${resultScreenStyles.icon} h-16 w-16`}
                            animate={animate ? { scale: [1, 1.05, 1] } : {}}
                            transition={animate ? { duration: 0.6 } : {}}
                            alt="XP Icon"
                        />
                        <span
                            className={`${resultScreenStyles.counter} text-white200`}
                        >
                            +
                            <CountUp
                                key={xp}
                                isCounting
                                end={xp}
                                duration={1}
                            />
                        </span>
                    </div>
                    <div className={`${resultScreenStyles.coinsWrapper}`}>
                        <motion.img
                            src={coinIcon}
                            className={`${resultScreenStyles.icon} h-16 w-16`}
                            animate={animate ? { scale: [1, 1.05, 1] } : {}}
                            transition={animate ? { duration: 0.6 } : {}}
                            alt="Coin Icon"
                        />
                        <span
                            className={`${resultScreenStyles.counter} text-white200`}
                        >
                            +
                            <CountUp
                                key={coins}
                                isCounting
                                end={coins}
                                duration={1}
                            />
                        </span>
                    </div>
                </div>
                {isShowLandscapeProgress && (
                    <div className="relative mt-5 flex w-full items-center justify-center">
                        <span className="absolute top-[-5px] text-center font-bold text-white">
                            {Math.min(
                                landscapeProgress,
                                PROGRESS_BAR_MAX_VALUE
                            )}
                            /{PROGRESS_BAR_MAX_VALUE}
                        </span>
                        <ProgressBar
                            initialValue={landscapeProgress}
                            progressBarValue={landscapesProgress}
                            height="h-3"
                            bgColor={COLORS.orange}
                            unfilledColor="bg-white/25"
                            borderColor="border-none"
                        />
                        <img
                            src={landscapeIcon}
                            className="ml-[-16px] h-12 w-12"
                            alt="Landscape Icon"
                        />
                    </div>
                )}
                <div
                    className={`${resultScreenStyles.learnedItemsView} mt-6 text-center`}
                >
                    <h3>
                        {getLabel('results-screen.learned-words')}
                        {': '}
                        <CountUp
                            key={wordsLearnedCounter}
                            isCounting
                            start={wordsLearnedBefore}
                            end={wordsLearnedCounter}
                            duration={0.5}
                        />
                    </h3>
                </div>
            </Panel>
            {!isUnlockAvailable ? (
                <TextButton
                    onClick={onContinuePress}
                    text={getLabel('button.continue')}
                    isDisabled={isLoading}
                    isLoading={isLoading}
                />
            ) : (
                <TextButton
                    onClick={onUnlockPress}
                    text={getLabel('gifts-progress.unlock-landscape')}
                    isDisabled={isLoading}
                    isLoading={isLoading}
                />
            )}
        </div>
    );
};

export default ResultsScreen;

import { useEffect } from 'react';
import { closingBehavior } from '@telegram-apps/sdk-react';

const useDisableClosingConfirmation = () => {
    useEffect(() => {
        closingBehavior.disableConfirmation();
    }, []);
};

export default useDisableClosingConfirmation;

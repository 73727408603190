import { FC } from 'react';
import classNames from 'classnames';

import VolumeIcon from '@/components/icons/VolumeIcon';
import emitter from '@/events/emitter';
import { PhraseInfoLocalized } from '@/types/category';

interface PhraseItemProps {
    item: PhraseInfoLocalized;
    learnt: boolean;
    hideDot?: boolean;
}

const PhraseItem: FC<PhraseItemProps> = ({ item, learnt, hideDot }) => {
    return (
        <div className="mt-3 flex items-center justify-between py-2.5">
            <div>
                <p
                    className={classNames(
                        'text-lg font-bold',
                        learnt ? 'text-white' : 'text-white/20'
                    )}
                >
                    {item.sentence}
                </p>
                <p
                    className={classNames(
                        'mt-1 text-base',
                        learnt ? 'text-white' : 'text-white/20'
                    )}
                >
                    {item.translation}
                </p>
            </div>
            <div
                className={classNames(
                    'flex w-1/6 items-center',
                    hideDot ? 'justify-end' : 'justify-between'
                )}
            >
                {!hideDot && (
                    <div
                        className={classNames(
                            'h-4 w-4 rounded-full',
                            learnt ? 'bg-orange' : 'bg-white/10'
                        )}
                    ></div>
                )}
                <button
                    onClick={() =>
                        emitter.emit('playAudioByUrl', {
                            audioUrl: item.audioUrl
                        })
                    }
                    disabled={!learnt}
                    className="ml-2"
                >
                    <VolumeIcon
                        width={18}
                        height={18}
                        color="white"
                        opacity={learnt ? '100%' : '20%'}
                    />
                </button>
            </div>
        </div>
    );
};

export default PhraseItem;
